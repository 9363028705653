import { createContext, useContext } from 'react';
import moment from 'moment';
import { useFetch } from '../hooks/useFetch';
import { EngagementMonthlyScore, MeasureQuery, TeamScore } from '../types/metrics';
import { DATE_ID_FORMAT, MIN_DATE_ID } from '../common/constants';
import { Spin } from 'antd';
import { fetchMonthlyScore, fetchScores } from '../apis/scoreClient';
import { transform } from '../utils/engagementData';
import { TeamContext } from './TeamContext';

export interface IEngagementData {
  scores: EngagementMonthlyScore[];
  teamId: number;
  dateId: string;
  teamScores: TeamScore[];
}

export const EngagementDataContext = createContext<IEngagementData>(null);

interface IProps {
  id: string | number;
  children: React.ReactNode;
  dateId: string;
}

export const EngagementDataContextProvider = ({ dateId, id, children }: IProps) => {
  const query: MeasureQuery = {
    startDateId: moment(MIN_DATE_ID).format(DATE_ID_FORMAT),
    parentId: id,
  };
  const { data: [scores, teamScore] = [], loading, error } = useFetch(
    () => Promise.all([fetchMonthlyScore(query), fetchScores(query)]),
    [id]
  );

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (loading) {
    return <Spin />;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { teams } = useContext(TeamContext);
  const { teamScores } = transform(teamScore, teams, dateId);
  return (
    <EngagementDataContext.Provider value={{ scores, teamId: Number(id), dateId, teamScores }}>
      {children}
    </EngagementDataContext.Provider>
  );
};

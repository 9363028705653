import { EngagementMonthlyScore, ScoreCardData, ScoreQuery, TeamMonthlyScore } from '../types/metrics';
import { npsDataResponse } from '../types/nps';
import { StatisticsResponse } from '../types/statistic';
import { fetchAsync } from '../utils/fetch';
import { serializeQuery } from '../utils/stringUtils';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(serializeQuery(query));
  return urlParams.toString();
};

export const fetchScores = (query: ScoreQuery): Promise<ScoreCardData[]> =>
  fetchAsync(`/api/score?${toUrlSearchParams(query)}`);

export const fetchNPSData = (query: ScoreQuery): Promise<npsDataResponse[]> =>
  fetchAsync(`/api/score/NPSData?${toUrlSearchParams(query)}`);

export const fetchStatistics = (query: ScoreQuery): Promise<StatisticsResponse> =>
  fetchAsync(`/api/score/StatisticsScore?${toUrlSearchParams(query)}`);

export const fetchMonthlyScore = (query: ScoreQuery): Promise<EngagementMonthlyScore[]> =>
  fetchAsync(`/api/score/MonthlyScore?${toUrlSearchParams(query)}`);

export const ExportScore = async (DateId: number, TeamType: string) => {
  const result = await fetchAsync(`/api/score/ExportScore?DateId=${DateId}&TeamType=${TeamType}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportNPS = async (DateId: number, TeamType: string) => {
  const result = await fetchAsync(`/api/score/ExportNps?DateId=${DateId}&TeamType=${TeamType}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportStatisticsScore = async (query: ScoreQuery) => {
  const result = await fetchAsync(`/api/excel/ExportStatisticsScore?${toUrlSearchParams(query)}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportStatisticsPSScore = async (query: ScoreQuery) => {
  const result = await fetchAsync(`/api/excel/ExportStatisticsPSScore?${toUrlSearchParams(query)}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const ExportStatisticsNPSScore = async (query: ScoreQuery) => {
  const result = await fetchAsync(`/api/excel/ExportStatisticsNPSScore?${toUrlSearchParams(query)}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

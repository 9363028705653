import { Gauge } from '@ant-design/charts';
import { Empty } from 'antd';
import { useContext } from 'react';
import {
  ABOVE_TARGET,
  BELOW_TARGET,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  COLOR_ON_TARGET,
} from '../common/constants';
import { ProgramDataContext } from '../contexts/ProgramDataContext';

export const ProgramScore = () => {
  const {
    programScore: { score, scoreMoM },
  } = useContext(ProgramDataContext);

  let content: React.ReactNode;
  if (score == null) {
    content = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    content = (
      <div>
        <Gauge
          height={300}
          range={{
            ticks: [0, BELOW_TARGET, ABOVE_TARGET, 1],
            color: [COLOR_BELOW_TARGET, COLOR_ON_TARGET, COLOR_ABOVE_TARGET],
          }}
          percent={Number(score.toFixed(2))}
          statistic={{
            content: {
              formatter: (datum, data) => {
                return `${datum.percent}`;
              },
            },
          }}
        />
        {scoreMoM != null && (
          <div className="gauge-text">
            <span className={`kpi-badge ${scoreMoM < 0 ? 'kpi-decrease' : 'kpi-increase'}`}>
              <span className="kpi-indicator">{scoreMoM < 0 ? '▼' : '▲'}</span>
              <span className="kpi-value">{scoreMoM}%</span>
              <span className="kpi-unit">(MOM)</span>
            </span>
          </div>
        )}
      </div>
    );
  }
  return <div className="gauge-container">{content}</div>;
};

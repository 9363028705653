import styled from 'styled-components';

export const StyledLinkCell = styled.span`
  a {
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      text-decoration: underline;
    }
  }

  .icon-external-link {
    margin-left: 8px;
    margin-bottom: -3px;
  }
`;

import { ChangePasswordModel, NewUserModel, UserModel, UserResponse } from '../types/user';
import { fetchAsync } from '../utils/fetch';

export const fetchUsers = (): Promise<UserResponse[]> => fetchAsync('/api/user');
export const createUser = (user: NewUserModel): Promise<UserResponse> =>
  fetchAsync(`/api/user`, {
    method: 'POST',
    body: user,
  });

export const fetchUsersById = (id: number | string): Promise<UserResponse> => fetchAsync(`/api/user/${id}`);

export const updateUser = (id: number, user: UserModel): Promise<UserResponse> =>
  fetchAsync(`/api/user/${id}`, {
    method: 'PUT',
    body: user,
  });

export const deleteUser = (id: number): Promise<void> =>
  fetchAsync(`/api/user/${id}`, {
    method: 'DELETE',
  });

export const changePassword = (id: number | string, model: ChangePasswordModel): Promise<void> =>
  fetchAsync(`/api/user/${id}/changepassword`, {
    method: 'PATCH',
    body: model,
  });

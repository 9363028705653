import { uniq } from 'lodash';
import { useContext } from 'react';
import { EngagementDataContext } from '../contexts/EngagementDataContext';
import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { TeamContext } from '../contexts/TeamContext';
import { ChildMonthlyMeasure, MonthlyData } from '../types/metrics';

export const useEngagementData = () => {
  const { teams } = useContext(TeamContext);
  const { scores, teamId } = useContext(ProjectDataContext);
  const { scores: childScores } = useContext(EngagementDataContext);
  const dates = scores ? uniq([...scores].map((item) => item.dateId)).sort() : [];

  let formatMonthlyData: MonthlyData[] = [];
  childScores.map((row) => {
    const elementData: MonthlyData = {
      type: row?.name,
      label: row?.name.concat(row?.categoryName),
      theme: row?.categoryName,
      color: row?.color,
    };

    dates.forEach((date) => {
      let dataByDate = row.kpiData?.find((item) => item.dateId === date);
      const childColGroups: Record<string, ChildMonthlyMeasure> = {};
      dataByDate?.childrenData?.forEach((item) => {
        const childTeam = teams.find((team) => team.id === item.teamId);
        childColGroups[childTeam.name] = {
          name: childTeam.name,
          teamId: item.teamId,
        } as ChildMonthlyMeasure;
        childColGroups[childTeam.name].actual = item?.actual;
        childColGroups[childTeam.name].target = item?.target;
        childColGroups[childTeam.name].score = item?.score;
      });
      elementData[date] = {
        actual: dataByDate?.actual,
        target: dataByDate?.target,
        score: dataByDate?.score,
        children: childColGroups,
      };
    });
    formatMonthlyData.push(elementData);
  });
  const sortmonthlyScore = formatMonthlyData.sort((a, b) => (a.theme.toLowerCase() > b.theme.toLowerCase() ? 1 : -1));
  return { monthlyData: sortmonthlyScore, dates, childScores, teamId };
};

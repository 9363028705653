import React, { useEffect, useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Select, Tag, Checkbox } from 'antd';
import { Entity } from '../types/entity';
import { SelectOptions } from '../types/team';
import { ColumnType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { ColumnGroupType, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { Link } from 'react-router-dom';

export interface EditableColumn<T> extends ColumnType<T> {
  inputType?: 'number' | 'text' | 'select' | 'comment' | 'colorSelect' | 'checkbox';
  diableEditCell?: (record: T) => boolean;
  inputProps?: any;
  options?: SelectOptions<any>[];
  editable?: boolean;
  rules?: any[];
}

interface EditableCellProps<T extends Entity> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType?: 'number' | 'text' | 'select' | 'comment' | 'colorSelect' | 'checkbox';
  inputProps?: any;
  record: T;
  index: number;
  children: React.ReactNode;
  rules?: any[];
  options?: SelectOptions<any>[];
  diableEditCell?: (record: T) => boolean;
}

export function EditableCell<T extends Entity>({
  editing,
  dataIndex,
  title,
  inputType,
  inputProps,
  record,
  index,
  children,
  rules,
  options,
  diableEditCell,
  ...restProps
}: EditableCellProps<T>) {
  const { TextArea } = Input;
  let inputNode = <Input />;
  let content = children;
  const allowEditCell = !diableEditCell || !diableEditCell(record);
  if (allowEditCell) {
    switch (inputType) {
      case 'number':
        inputNode = <InputNumber />;
        break;
      case 'select':
        inputNode = <Select options={options} suffixIcon={<SearchOutlined />} {...inputProps} />;
        content = options?.find((item) => item.value === record[dataIndex])?.label ?? children;
        break;
      case 'comment':
        inputNode = <TextArea rows={4} />;
        break;
      case 'checkbox':
        inputNode = <Checkbox />;
        break;
      case 'colorSelect':
        let item = options?.find((x) => x.label === record[dataIndex]);
        inputNode = (
          <Select suffixIcon={<SearchOutlined />} {...inputProps}>
            {options.map((x) => {
              return (
                <span key={x.label} style={{ backgroundColor: x.label }}>
                  {x.value}
                </span>
              );
            })}
          </Select>
        );
        content = (
          <Tag style={{ color: 'black' }} key={item?.label} color={item?.label}>
            {item?.value}
          </Tag>
        );
        break;
      default:
        break;
    }
  }
  return (
    <td {...restProps}>
      {editing && allowEditCell ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }} rules={rules}>
          {inputNode}
        </Form.Item>
      ) : (
        content
      )}
    </td>
  );
}

interface IProps<T> extends TableProps<T> {
  data: T[];
  columns: EditableColumn<T>[];
  onSave?: (id: number, record: T) => Promise<any>;
  onDelete?: (id: number, record?: T) => Promise<any>;
  onChangePassword?: (id: number) => Promise<any>;
  isChangePassword?: boolean;
  ModifyTeamWeight?: boolean;
  KpiDefinition?: boolean;
  DeleteTargetConfig?: boolean;
  pagination?: TablePaginationConfig;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => void;
}

export function EditableTable<T extends Entity>({
  data: originData,
  columns,
  onSave,
  onDelete,
  onChangePassword,
  pagination,
  onChange,
  isChangePassword,
  ModifyTeamWeight,
  DeleteTargetConfig,
  KpiDefinition,
  ...rest
}: IProps<T>) {
  const [form] = Form.useForm();
  const [data, setData] = useState<T[]>(originData);
  const [editingKey, setEditingKey] = useState<number>();
  const [pageSize, setPageSize] = useState<number>(20);
  useEffect(() => {
    setData(originData);
  }, [originData]);

  const isEditing = (record: T) => record.id === editingKey;

  const operationColumn: EditableColumn<T> = {
    title: 'Actions',
    dataIndex: 'operation',
    // eslint-disable-next-line react/display-name
    render: (_: any, record: T) => {
      const editable = isEditing(record);
      return DeleteTargetConfig ? (
        <>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id, record)}>
            <Button type="link">Delete</Button>
          </Popconfirm>
        </>
      ) : editable ? (
        <span>
          {KpiDefinition ? (
            <>
              <Popconfirm
                title="Sure to Update? if you change, system will be caculate score"
                onConfirm={() => save(record)}
              >
                <Button type="link">Save</Button>
              </Popconfirm>
            </>
          ) : (
            <Button type="link" onClick={() => save(record)}>
              Save
            </Button>
          )}
          <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <Button type="link">Cancel</Button>
          </Popconfirm>
        </span>
      ) : (
        <span>
          {ModifyTeamWeight ? (
            <Button type="link">
              <Link to={`/admin/Modify-teamweight/${record.id}`}>Edit</Link>
            </Button>
          ) : (
            <>
              <Button type="link" onClick={() => edit(record)}>
                Edit
              </Button>
              <Popconfirm
                title="Sure to delete? If you change, please edit teamweight."
                onConfirm={() => handleDelete(record.id, record)}
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            </>
          )}
          {isChangePassword && (
            <Button type="link" onClick={() => handleChangePassword(record.id)}>
              Reset password
            </Button>
          )}
        </span>
      );
    },
  };
  const edit = (record: T) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey(null);
  };

  const handleDelete = async (id: number, record: T) => {
    try {
      await onDelete?.(id, record);
    } catch (error) {}
  };

  const handleChangePassword = async (id: number) => {
    try {
      await onChangePassword?.(id);
    } catch (error) {}
  };

  const save = async (record: T) => {
    try {
      const row = (await form.validateFields()) as T;
      await onSave?.(record.id, { ...record, ...row });
      setEditingKey(null);
    } catch (errInfo) {}
  };
  const makeEditableColumn = (col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: T) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        inputProps: col.inputProps,
        rules: col.rules,
        options: col.options,
        title: col.title,
        editing: isEditing(record),
        diableEditCell: col.diableEditCell,
      }),
    } as EditableColumn<T>;
  };

  const mergedColumns = [...columns, operationColumn].map((col: EditableColumn<T>) => {
    const colGroup = col as ColumnGroupType<T>;
    if (colGroup.children) {
      colGroup.children = colGroup.children.map((childCol) => makeEditableColumn(childCol));
      return colGroup;
    }

    return makeEditableColumn(col);
  });

  return (
    <Form form={form} component={false}>
      <Table
        {...rest}
        size="small"
        rowKey="id"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        onChange={onChange}
      />
    </Form>
  );
}

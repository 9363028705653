import { Empty, Grid } from 'antd';
import { orderBy, round } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';

import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import {
  COLOR_NPS_STATUS,
  DATE_ID_FORMAT,
  MONTH_FORMAT,
  MONTH_YEAR_FORMAT,
  DETRACTOR_COLOR,
  PASSIVE_COLOR,
  PROMOTER_COLOR,
} from '../../common/constants';
import { DualAxes } from '@ant-design/charts';
import { DualAxesConfig } from '@ant-design/charts/es';
import { IProgramTrendingData } from '../../types/metrics';

export interface IData {
  month: string;
  type: string;
  value: number;
}
export interface INpsStatus {
  month: string;
  score: number;
}
const { useBreakpoint } = Grid;

export const ProgramNPSTrending = () => {
  const { programDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);
  const { md } = useBreakpoint();

  const data: IData[] = [];
  const npsStatus: INpsStatus[] = [];
  orderBy(programDatas, (item) => item.dateId).forEach((item) => {
    const detractor: IData = {
      month: moment(item.dateId, DATE_ID_FORMAT).format(MONTH_YEAR_FORMAT),
      type: 'Detractor',
      value: item.detractorCount,
    };
    const passive: IData = {
      month: moment(item.dateId, DATE_ID_FORMAT).format(MONTH_YEAR_FORMAT),
      type: 'Passive',
      value: item.passiveCount,
    };
    const promotor: IData = {
      month: moment(item.dateId, DATE_ID_FORMAT).format(MONTH_YEAR_FORMAT),
      type: 'Promoter',
      value: item.promoterCount,
    };
    const nps: INpsStatus = {
      month: moment(item.dateId, DATE_ID_FORMAT).format(MONTH_YEAR_FORMAT),
      score: item.npsPerformance,
    };
    npsStatus.push(nps);
    data.push(detractor);
    data.push(passive);
    data.push(promotor);
  });
  const config: DualAxesConfig = {
    data: [data, npsStatus],
    xField: 'month',
    yField: ['value', 'score'],
    yAxis: [
      {
        label: {
          formatter: (v) => {
            return Number(v) * 100 + '%';
          },
        },
      },
    ],
    meta: {
      score: {
        alias: 'NPS',
      },
    },
    legend: {
      position: 'top',
      flipPage: false,
    },
    geometryOptions: [
      {
        geometry: 'column',
        isStack: true,
        isPercent: true,
        seriesField: 'type',
        columnWidthRatio: 0.8,
        color: [DETRACTOR_COLOR, PASSIVE_COLOR, PROMOTER_COLOR],
        label: {
          position: 'middle',
          formatter: ({ value }: any) => {
            return `${round(value * 100, 1)}%`;
          },
          layout: [{ type: 'adjust-color' }],
          style: {
            fontWeight: md ? 'normal' : 'bold',
          },
        },
      },
      {
        geometry: 'line',
        color: COLOR_NPS_STATUS,
        point: {
          shape: 'diamond',
          size: 5,
        },
        label: {
          position: 'top',
        },
      },
    ],
  };

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return <DualAxes {...config} />;
  }
};

import { Button, Empty, Modal, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { AggregatedScore, CategoryWeightResponse, KPIWeightResponse, MonthlyData } from '../types/metrics';
import { ABOVE_TARGET, BELOW_TARGET, DATE_ID_FORMAT } from '../common/constants';
import { ColumnsType } from 'antd/lib/table';
import './MonthlyData.scss';
import { orderBy } from 'lodash';
import { MonthlyDataToolbar } from './EngagementMonthlyData.styled';
import { useFetch } from '../hooks/useFetch';
import { fetchKPIByteam } from '../apis/customMetric';
import { TeamWeightMonthlyTable } from './TeamWeightMonthlyTable';
import { fetchCategoryByteam } from '../apis/fetchCategory';
import { CloseOutlined } from '@ant-design/icons';

const DATE_COL_WIDTH = 75;
const LABEL_COL_WIDTH = 200;

const getCellAttributes = (record: AggregatedScore, dateId: number): any => {
  const score = record[dateId]?.score;
  if (score == null) {
    return { 'data-target': 'null' };
  }

  if (score < BELOW_TARGET) {
    return { 'data-target': 'below' };
  }

  if (score < ABOVE_TARGET) {
    return { 'data-target': 'on' };
  }

  return { 'data-target': 'above' };
};

const getRowSpans = (arr, key) => {
  let sameValueLength = 0;
  const rowSpans = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    if (i === 0) {
      rowSpans[i] = sameValueLength + 1;
      continue;
    }
    if (arr[i][key] === arr[i - 1][key]) {
      rowSpans[i] = 0;
      sameValueLength++;
    } else {
      rowSpans[i] = sameValueLength + 1;
      sameValueLength = 0;
    }
  }
  return rowSpans;
};

const createMonthlyColumns = (dates: number[], targetDates: number[], rowSpans: any[]) => {
  const dateCols: ColumnsType = [
    {
      title: 'Category',
      dataIndex: 'theme',
      width: LABEL_COL_WIDTH,
      key: 'theme',
      fixed: 'left',
      render: (value, record: MonthlyData, index) => {
        const obj = {
          children: record.theme,
          props: { rowSpan: rowSpans[index] },
        };
        return obj;
      },
      onCell: (record: MonthlyData) => ({
        style: {
          backgroundColor: record.color,
        },
      }),
    },
    {
      dataIndex: 'label',
      title: 'KPI',
      fixed: 'left',
      width: LABEL_COL_WIDTH,
      className: 'monthly-data-name-column',
      render: (value, record: MonthlyData, index) => record.type,
      onCell: (record: MonthlyData) => ({
        style: {
          backgroundColor: record.color,
        },
      }),
    },
  ];

  const sortedDates = orderBy(dates, (a) => a, 'desc');
  const sortedTargets = orderBy(targetDates, (a) => a, 'desc');

  let currentTarget = sortedTargets.shift();
  let targetDateId = currentTarget ?? sortedDates[sortedDates.length - 1];

  sortedDates.forEach((dateId) => {
    const date = moment(`${dateId}`, DATE_ID_FORMAT);
    // Start new target date ranges
    if (targetDateId > dateId) {
      currentTarget = sortedTargets.shift();
      targetDateId = currentTarget;
    }

    // Only add target column at start of month ranges
    if (currentTarget) {
      dateCols.push({
        title: 'Target',
        dataIndex: [currentTarget, 'target'],
        width: DATE_COL_WIDTH,
        align: 'center',
        className: 'monthly-data-column target',
        render: (value, record, index) => value?.toFixed(2),
      });
      currentTarget = null;
    }

    dateCols.push({
      title: date.format('MMM-YY'),
      children: [
        {
          dataIndex: [dateId, 'actual'],
          title: 'Actual',
          width: DATE_COL_WIDTH,
          align: 'center',
          className: 'monthly-data-column actual',
          onCell: (record: AggregatedScore) => getCellAttributes(record, dateId),
          render: (val: number) => val?.toFixed(2),
        },
        {
          dataIndex: [dateId, 'score'],
          title: 'Score',
          width: DATE_COL_WIDTH,
          align: 'center',
          className: 'monthly-data-column score',
          onCell: (record: AggregatedScore) => getCellAttributes(record, dateId),
          render: (val: number) => val?.toFixed(2),
        },
      ],
    });
  });

  return dateCols;
};

interface IProps {
  projectId: number;
  dateId: number;
}

export const MonthlyTable = ({ projectId, dateId }: IProps) => {
  const { monthlyData, dates, targetDates } = useContext(ProjectDataContext);
  const [kpiWeight, setKpiWeight] = useState<KPIWeightResponse[]>([]);
  const [categoryWeight, setCategoryWeight] = useState<CategoryWeightResponse[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const kpiData = await fetchKPIByteam(dateId, projectId, false);
        setKpiWeight(kpiData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [projectId, dateId]);

  useEffect(() => {
    (async function () {
      try {
        const catogryData = await fetchCategoryByteam(kpiWeight[0]?.dateId, projectId);
        setCategoryWeight(catogryData);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [kpiWeight]);

  const showModal = () => {
    Modal.info({
      // eslint-disable-next-line react/display-name
      title: (
        <>
          <span>Team weight configuration - {dateId}</span>
          <Button type="link" onClick={() => Modal.destroyAll()} style={{ float: 'right' }}>
            <CloseOutlined />
          </Button>
        </>
      ),
      width: '60%',
      content: <TeamWeightMonthlyTable kpiWeight={kpiWeight} categoryWeight={categoryWeight} dateId={dateId} />,
      onOk() {},
      okText: 'Close',
    });
  };
  if (dates.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const rowSpans = getRowSpans(monthlyData, 'theme');
  const cols = createMonthlyColumns(dates, targetDates, rowSpans);
  const scrollX = `calc(${LABEL_COL_WIDTH}px + 50%)`;

  return (
    <div>
      <MonthlyDataToolbar>
        <Button type="link" onClick={showModal} className="btn-view-config" style={{ paddingLeft: 0, paddingTop: 0 }}>
          View team configuration
        </Button>
      </MonthlyDataToolbar>
      <Table
        className="project-monthly-data-table"
        columns={cols}
        bordered
        rowKey="label"
        dataSource={monthlyData}
        scroll={{ x: scrollX }}
        pagination={false}
      />
    </div>
  );
};

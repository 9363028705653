import { Empty } from 'antd';
import { orderBy, minBy } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';

import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import { DATE_ID_FORMAT } from '../../common/constants';
import { Line } from '@ant-design/charts';
import { LineConfig } from '@ant-design/charts/es';
import { TeamContext } from '../../contexts/TeamContext';
import { IProgramTrendingData } from '../../types/metrics';

export const DepartmentTreding = () => {
  const { teams } = useContext(TeamContext);
  const { departmentDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);
  const data = orderBy(departmentDatas, (item) => item.dateId).map((item) => ({
    month: moment(item.dateId, DATE_ID_FORMAT).format('MMM YY'),
    key: teams.find((t) => t.id === item.teamId)?.name,
    value: Number(item.score.toFixed(2)),
  }));
  const config: LineConfig = {
    data: data,
    height: 400,
    xField: 'month',
    yField: 'value',
    seriesField: 'key',
    point: {
      size: 3,
      shape: 'square',
    },
    label: {
      position: 'top',
    },
    legend: {
      position: 'top',
      flipPage: false,
    },
    yAxis: {
      max: 1.05,
      tickInterval: 0.05,
      min: Math.min(minBy(data, (item) => item.value)?.value, 0.5),
    },
  };

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return <Line {...config} />;
  }
};

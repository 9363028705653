import { GuidelineQuery, GuidelineResponse } from '../types/guideline';
import { fetchAsync } from '../utils/fetch';

export const fetchGuideline = (): Promise<GuidelineResponse> => fetchAsync('/api/guideline');

export const createGuideline = (guideline: GuidelineQuery[]): Promise<GuidelineResponse> =>
  fetchAsync(`/api/guideline`, {
    method: 'POST',
    body: guideline,
  });

import { Input, Typography, Card, Alert, Table, Spin } from 'antd';
import { Row, Col } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { deleteProjectMetricFact, deleteTarget, fetchProjectMetricFact } from '../apis/targetClient';
import { useFetch } from '../hooks/useFetch';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import '../pages/TargetConfiguration.scss';
import { ColumnType } from 'antd/lib/table/interface';
import { PageTitle } from '../layouts/AdminLayout.styled';
import { Helmet } from 'react-helmet';
import { TeamContext } from '../contexts/TeamContext';
import { fetchKPI } from '../apis/customMetric';
import { TeamTarget } from '../types/target';
import { EditableTable } from '../components/EditableTable';
import { deleteItem } from '../utils/tableUtils';
import { fetchCategory } from '../apis/fetchCategory';

const { Title } = Typography;
const { Search } = Input;

interface IColumn extends ColumnType<any> {
  dataIndex: keyof TeamTarget;
  title?: string;
  className?: string;
}
interface HeatMapCellProps extends React.HTMLAttributes<HTMLElement> {
  dataIndex: string;
  record: any;
  min?: number;
  max?: number;
  children: React.ReactNode;
}

const createHeatmapColumns = (colNames: IColumn[], width: string | number): ColumnType<any>[] => {
  return colNames.map(
    (column) =>
      ({
        ...column,
        title: column.title || column.dataIndex,
        width,
        onCell: (record: any) =>
          ({
            dataIndex: column.dataIndex,
            record,
            min: 0.5,
            max: 1,
          } as any),
      } as ColumnType<any>)
  );
};

const TargetCell = ({ children, record, min, max, dataIndex, className, ...restProps }: HeatMapCellProps) => {
  let content: React.ReactNode = children;
  if (record) content = record[dataIndex];
  else content = null;
  return <td {...restProps}>{content}</td>;
};

const formatData = (plainData: any, teams: any) => {
  let listTarget = [];
  let keyItem = 0;
  plainData?.forEach((item) => {
    let customTarget = handleDataTarget(item, keyItem, teams);
    keyItem++;
    listTarget.push(customTarget);
  });
  return listTarget;
};
const handleDataTarget = (record: any, keyItem: number, teams: any) => {
  let list = {};
  list['id'] = keyItem;
  list['name'] = teams.find((t) => t.id === record.teamId)?.name;
  list['dateId'] = record.dateId;
  list['teamId'] = record.teamId;
  record.kpiData?.forEach((item) => {
    list[item.kpiName] = item.target;
  });
  return list;
};

export const TargetConfiguration = () => {
  const searchEl = useRef(null);
  const { data: originalProjectMetricFact } = useFetch(fetchProjectMetricFact);
  const { teams } = useContext(TeamContext);
  const { data: KpiList } = useFetch(() => fetchKPI());
  const { data: CategoryList } = useFetch(() => fetchCategory());
  const [tableData, setTableData] = useState<any[]>([]);

  const [paging, setPaging] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(15);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setTableData(formatData(originalProjectMetricFact, teams));
  }, [originalProjectMetricFact]);

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/display-name
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <Search
        ref={searchEl}
        allowClear
        placeholder="input search text"
        onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 200 }}
        value={selectedKeys[0]}
        onChange={(e: any) => {
          if (e._reactName === 'onChange') {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          } else {
            setSelectedKeys('');
            clearFilters();
            handleSearch('', confirm, dataIndex);
          }
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
      />
    ),
    // eslint-disable-next-line react/display-name
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) => {
      return true;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchEl.current.select(), 100);
    },
    render: (text: any) => {
      return text;
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex): any => {
    confirm();
    setFilter(selectedKeys);
    setPaging(1);
  };

  const metricsColumns = () => {
    const listColumnKPI = [];
    listColumnKPI.push({
      title: 'Team',
      dataIndex: 'name',
      fixed: 'left',
      sorter: (a: any, b: any) => 1,
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('name'),
      key: 'name',
      onCell: (data) => ({
        title: data.name,
      }),
    });
    listColumnKPI.push({
      title: 'Applied to',
      sorter: (a: any, b: any) => 1,
      dataIndex: 'dateId',
      defaultSortOrder: 'ascend',
      key: 'dateId',
      fixed: 'left',
    });
    KpiList?.sort((a, b) => (a.categoryId > b.categoryId ? 1 : -1))?.forEach((record) => {
      var KpiName = record.name;
      var color = CategoryList?.find((item) => item.id === record.categoryId)?.themeColor;
      listColumnKPI.push({ dataIndex: KpiName, className: `heatmap-column-${color?.replace('#', '')}` });
    });
    const collumns: ColumnType<TeamTarget>[] = [...createHeatmapColumns(listColumnKPI, '2%')];
    console.log(CategoryList, collumns);
    return collumns;
  };

  const onDelete = async (id: number) => {
    setLoading(true);
    try {
      var getItemData = tableData[id];
      await deleteTarget(getItemData.dateId, getItemData.teamId);
      const newData = deleteItem(id, tableData);
      setTableData(formatData(newData, teams));
      setLoading(false);
    } catch (error) {
      Alert(error.data?.error?.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Helmet>
        <title>Target Configuration</title>
      </Helmet>
      <Card className="custom-metrics-container">
        <Row className="filter-container">
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 24, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xl={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Row gutter={12}>
              <Col flex="auto" lg={{ span: 12, offset: 0 }} xl={{ span: 12, offset: 0 }} xxl={{ span: 8, offset: 0 }}>
                <PageTitle>
                  <Title level={3}>Target Configuration</Title>
                </PageTitle>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px', overflowX: 'auto' }}>
          <Col span={24}>
            {loading && <Spin indicator={antIcon} tip="Loading..." />}
            <EditableTable
              className="heatmap"
              rowKey="key"
              data={tableData}
              columns={metricsColumns()}
              rowClassName="editable-row"
              scroll={{ x: 'max-content' }}
              pagination={{
                pageSize: 15,
              }}
              bordered
              components={{
                body: {
                  cell: TargetCell,
                },
              }}
              DeleteTargetConfig={true}
              onDelete={onDelete}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

import { TeamModel, TeamResponse, NotiTeamModel, NotiTeamRequest } from '../types/team';
import { fetchAsync } from '../utils/fetch';
import { serializeQuery } from '../utils/stringUtils';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(serializeQuery(query));
  return urlParams.toString();
};

export const fetchTeams = (): Promise<TeamResponse[]> => fetchAsync('/api/team');

export const createTeam = (team: TeamModel): Promise<TeamResponse> =>
  fetchAsync(`/api/team`, {
    method: 'POST',
    body: team,
  });

export const updateTeam = (id: number, team: TeamModel): Promise<TeamResponse> =>
  fetchAsync(`/api/team/${id}`, {
    method: 'PUT',
    body: team,
  });

export const deleteTeam = (id: number): Promise<void> =>
  fetchAsync(`/api/team/${id}`, {
    method: 'DELETE',
  });

export const getNotiEngagement = (id: number): Promise<NotiTeamModel[]> =>
  fetchAsync(`/api/team/notified/${id}`, {
    method: 'Get',
  });

export const sendNotiEngagement = (query: NotiTeamRequest): Promise<void[]> =>
  fetchAsync(`/api/team/notify-import-data?${toUrlSearchParams(query)}`, {
    method: 'POST',
    body: query,
  });

import { Helmet } from 'react-helmet';
import { Button, Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { GuidelineContext } from '../../contexts/GuidelineContext';
import { TextEditor } from '../../components/Editor';
import './Guideline.styles.scss';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
const { Paragraph } = Typography;

export const GuidelineView = () => {
  const {
    user: { isAdmin },
  } = useContext(AuthContext);
  const { guideline } = useContext(GuidelineContext);
  const [guidelineData, setGuidelineData] = useState(EditorState.createEmpty());
  useEffect(() => {
    if (guideline[0]) {
      const htmlToDraftData = htmlToDraft(guideline[0].text);
      const { contentBlocks, entityMap } = htmlToDraftData;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setGuidelineData(editorState);
    }
    return () => {};
  }, [guideline]);

  const [editMode, setEditMode] = useState(false);
  const handleEditClick = () => {
    setEditMode(true);
  };
  return (
    <>
      <Helmet>
        <title>Guideline</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container guideline-container">
          <div className="guideline_section-title-container">
            <span className="guideline_section-title">VSC Guideline</span>
          </div>
          {editMode ? (
            <div className="guideline-editor">
              <TextEditor setMode={setEditMode} data={guidelineData} />
            </div>
          ) : (
            <>
              <div className="edit-action-buttons">
                {isAdmin && (
                  <Button size="large" type="primary" className="actions-btn" onClick={handleEditClick}>
                    Edit
                  </Button>
                )}
              </div>
              {/* send help */}
              <div className="guideline-view">
                {guideline && <Paragraph>{ReactHtmlParser(guideline[0]?.text)}</Paragraph>}
              </div>
            </>
          )}
        </section>
      </div>
    </>
  );
};

import { Spin } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { fetchUsers } from '../apis/userClient';
import { useFetch } from '../hooks/useFetch';
import { UserResponse } from '../types/user';

interface IUserContext {
  users: UserResponse[];
  setUsers: (users: UserResponse[]) => void;
}

export const UserContext = createContext<IUserContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const UserContextProvider = ({ children }: IProps) => {
  const [users, setUsers] = useState<UserResponse[]>();
  const { data: originalData, error: fetchError } = useFetch(fetchUsers);

  useEffect(() => {
    setUsers(originalData);
  }, [originalData]);

  if (fetchError) {
    return <div>{JSON.stringify(fetchError)}</div>;
  }

  if (!users) {
    return <Spin />;
  }

  return <UserContext.Provider value={{ users, setUsers }}>{children}</UserContext.Provider>;
};

import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es';
import { Empty } from 'antd';
import { useContext } from 'react';
import {
  SENTIMENT_POS_LABEL,
  SENTIMENT_NEUTRAL_LABEL,
  SENTIMENT_NEG_LABEL,
  SENTIMENT_POS_COLOR,
  SENTIMENT_NEUTRAL_COLOR,
  SENTIMENT_NEG_COLOR,
} from '../common/constants';
import { NpsDataContext } from '../contexts/NpsDataContext';

export const SentimentCategoriesByDep = () => {
  const { sentimentNpsData } = useContext(NpsDataContext);

  if (!sentimentNpsData || sentimentNpsData.clientSentiment == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  let npsData = [];

  sentimentNpsData.sentimentCategoriesByDepartment?.forEach((item) => {
    let negItem = { type: SENTIMENT_NEG_LABEL, name: item.name, value: item.countNeg };
    let neutralItem = { type: SENTIMENT_NEUTRAL_LABEL, name: item.name, value: item.countNeutral };
    let posItem = { type: SENTIMENT_POS_LABEL, name: item.name, value: item.countPos };

    npsData.push(negItem, neutralItem, posItem);
  });

  var config: ColumnConfig = {
    data: npsData,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
    height: 300,
    legend: {
      marker: {
        symbol: 'circle',
      },
      position: 'top',
      flipPage: false,
    },
    label: {
      content: (item) => {
        var percent = item.value;
        return percent === 0 ? '' : `${Math.round(percent * 100)}%`;
      },
      position: 'middle',
    },
    color: (_ref) => {
      var type = _ref.type;
      if (type === SENTIMENT_POS_LABEL) {
        return SENTIMENT_POS_COLOR;
      } else if (type === SENTIMENT_NEG_LABEL) {
        return SENTIMENT_NEG_COLOR;
      }
      return SENTIMENT_NEUTRAL_COLOR;
    },
    yAxis: {
      label: {
        formatter: (_, item) => {
          return `${item.value * 100}%`;
        },
      },
    },
  };
  if (npsData.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return <Column {...config} />;
};

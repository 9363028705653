import styled from 'styled-components';

export const ImportLayout = styled.div`
  background-color: rgb(248, 248, 248);
  margin-top: 35px;
  margin-left: 45px;
  margin-right: 45px;
  padding: 30px;
  border-radius: 10px;
  border: 2.5px solid rgb(228, 228, 228);
  position: relative;

  .ant-alert {
    top: 10px;
  }

  .table-error {
    margin-top: 20px;
    box-shadow: 0px 0px 5px 1px #ccc;
    border-radius: 5px;
  }
`;

export const ImportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ImportBody = styled.div`
  background-color: white;
  padding: 50px;
  box-shadow: 0px 0px 5px 1px #ccc;
  border-radius: 5px;
`;

export const ImportContent = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  .title {
    margin-right: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .month-picker {
    margin-left: 10px;
    color: red;
  }

  .has-value {
    color: green;
  }
`;

export const ImportDescription = styled.span`
  font-size: 16px;
  margin-right: 30px;
  display: flex;
  align-items: center;
`;

import { fetchAsync } from '../utils/fetch';

const fetchTeamData = async (teamId: number) => {
  const result = await fetchAsync('/api/score/template/' + teamId, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};
export default fetchTeamData;
const fetchPSFlowTrending = async (teamId: number) => {
  const result = await fetchAsync('/api/score/psflowtrending/' + teamId, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

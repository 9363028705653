import { Gauge } from '@ant-design/charts';
import { GAUGE_DETRACTOR, GAUGE_PROMOTER, GAUGE_EXCELLENT } from '../common/constants';
import { NpsDataContext } from '../contexts/NpsDataContext';
import { useContext } from 'react';
import { PROMOTER_COLOR, DETRACTOR_COLOR, PASSIVE_COLOR, EXCELLENT_COLOR } from '../common/constants';
import { Empty } from 'antd';
import { GaugeConfig } from '@ant-design/charts/es';
import { round } from 'lodash';

export const NpsStatus = () => {
  const {
    programScore: { npsPerformance, npsMoM },
  } = useContext(NpsDataContext);

  if (npsPerformance == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  var input = npsPerformance;
  var formatInput = (input + 100) / 200;
  var config: GaugeConfig = {
    axis: {
      label: {
        formatter: function formatter(v) {
          return Math.round((Number(v) - 0.5) * 200);
        },
      },
      subTickLine: { count: 3 },
    },
    percent: formatInput,
    statistic: {
      content: {
        style: {
          fontSize: '36px',
          lineHeight: '36px',
        },
        formatter: () => {
          return `${round(input, 1)}`;
        },
      },
    },
  };
  return (
    <div className="gauge-container">
      <Gauge
        {...config}
        height={300}
        range={{
          ticks: [0, GAUGE_DETRACTOR, GAUGE_PROMOTER, GAUGE_EXCELLENT, 1],
          color: [DETRACTOR_COLOR, PASSIVE_COLOR, PROMOTER_COLOR, EXCELLENT_COLOR],
        }}
      />
      {npsMoM != null && (
        <div className="gauge-text">
          <span className={`kpi-badge ${npsMoM < 0 ? 'kpi-decrease' : 'kpi-increase'}`}>
            <span className="kpi-indicator">{npsMoM < 0 ? '▼' : '▲'}</span>
            <span className="kpi-value">{npsMoM}</span>
            <span className="kpi-unit">(MOM)</span>
          </span>
        </div>
      )}
    </div>
  );
};

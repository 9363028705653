/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Timeline, Radio, Space, Button, Tag, TablePaginationConfig, notification, Spin } from 'antd';
import { Card } from '../../components/Card';
import { Collapse } from 'antd';
import { EditableColumn, EditableTable } from '../../components/EditableTable';
import Title from 'antd/lib/typography/Title';
import { useContext, useEffect, useState } from 'react';
import { StyledTeamSelect } from '../../components/NoteDrawer.styled';
import { TeamSelect } from '../../components/TeamSelect';
import './HealthCheck.scss';
import { DATE_ID_FORMAT } from '../../common/constants';
import { Helmet } from 'react-helmet';
import { useFetch } from '../../hooks/useFetch';
import moment from 'moment';
import { fetchScores } from '../../apis/scoreClient';
import { CreateCommentActual, deleteComment, fetchMeasureNameList, fetchMissingActual } from '../../apis/healthyClient';
import { ScoreResponse } from '../../types/metrics';
import { MissingActualRequest, MissingActualResponse } from '../../types/healthy';
import { parseInt } from 'lodash';
import { TeamContext } from '../../contexts/TeamContext';
import { deleteItem, updateItem } from '../../utils/tableUtils';
import { LoadingOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

export const HealthCheckDashboard = () => {
  const [validYears, setValidYears] = useState<string[]>([]);
  const [filterDateId, setFilterDateId] = useState(null);
  const [filterValue, setFilterValue] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [filterDisabled, setFilterDisabled] = useState(true);
  const { data: scoreData } = useFetch(() => fetchScores({ startDateId: '' }));
  const [validMonthsYears, setValidMonthsYears] = useState<number[]>([]);
  const [datafilter, setDataFilter] = useState<MissingActualResponse[]>();
  const [filterMeasureValue, setFilterMeasureValue] = useState<string[]>(['All']);
  const { data: filterFormMeasure } = useFetch(fetchMeasureNameList);
  const [missingActual, setMissingActual] = useState<MissingActualResponse[]>();
  const { teams } = useContext(TeamContext);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  const breakpoint = 415;

  const handleMonthChanged = (dateId: string) => {
    let dateNumber = parseInt(dateId, 10);
    setFilterDateId(dateNumber);
    getMissingActual();
  };
  const onFilterChange = (e) => {
    setFilterValue(e.target.value);
    const TorF = filterValue === 2 ? true : false;
    TorF && setSelectedTeam(undefined);
    setFilterDisabled(TorF);
  };

  const handleTeamChange = (e) => {
    setSelectedTeam(e);
  };

  function getValidMonth(noteData: ScoreResponse[]) {
    let validMonths = Array.from(new Set(noteData?.map((item: ScoreResponse) => item.dateId)));
    return validMonths;
  }

  const getMissingActual = async () => {
    setLoading(true);
    let missingActualQuery: MissingActualRequest = {
      dateid: filterDateId,
      teamId: selectedTeam || 0,
    };
    try {
      const Actuals = await fetchMissingActual(missingActualQuery);
      Actuals && setMissingActual(Actuals);
    } catch (error) {
      return <div>{JSON.stringify(error)}</div>;
    }
    setLoading(false);
  };
  useEffect(() => {
    getMissingActual();
  }, [filterDateId, selectedTeam]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);
  interface props {
    id: number;
  }

  useEffect(() => {
    const validMonthsList = getValidMonth(scoreData);
    setValidMonthsYears(validMonthsList);
    const years = validMonthsList?.map((year) => moment(year, DATE_ID_FORMAT).format('YYYY'));
    setValidYears(Array.from(new Set(years)));
    setFilterDateId(validMonthsList[0]);
  }, [scoreData]);

  const CustomCell = (value: MissingActualResponse) => {
    return <div className="CommentStyle">{value}</div>;
  };
  const columns: EditableColumn<MissingActualResponse>[] = [
    {
      title: '#',
      dataIndex: 'index',
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
      width: '4%',
      fixed: 'left',
      editable: false,
    },
    {
      title: 'Team',
      dataIndex: 'id',
      width: '15%',
      fixed: 'left',
      editable: false,
      render: (value) => teams.filter((x) => x.id === value)[0]?.name,
    },
    {
      title: 'Engagement',
      dataIndex: 'id',
      width: '20%',
      editable: false,
      render: (value) => teams.filter((x) => x.id === teams.filter((x) => x.id === value)[0]?.parentId)[0]?.name,
    },
    {
      title: 'Missing Measures',
      dataIndex: 'missingMeasures',
      width: '30%',
      editable: false,
      // eslint-disable-next-line react/display-name
      render: (missingMeasures) => (
        <>
          {missingMeasures.map((tag) => {
            let color = filterFormMeasure?.filter((x) => x.title === tag)[0]?.color;
            return (
              <Tag className="missing-actual-tag" color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: '20%',
      editable: true,
      inputType: 'comment',
      // eslint-disable-next-line react/display-name
      render: (value) => CustomCell(value),
    },
  ];

  const handleFilter = (value) => {
    if (value.title === 'All') {
      setFilterMeasureValue(['All']);
    } else {
      let query = [];
      if (filterMeasureValue.find((item) => item === 'All')) {
        setFilterMeasureValue([value.title]);
      } else {
        if (filterMeasureValue.find((item) => item === value.title)) {
          query = filterMeasureValue.filter((item) => item !== value.title);
          setFilterMeasureValue(query);
        } else {
          query = filterMeasureValue.concat(value.title);
          setFilterMeasureValue(query);
        }
        if (query.length === 0) {
          setFilterMeasureValue(['All']);
        }
      }
    }
  };

  useEffect(() => {
    if (filterMeasureValue[0] === 'All') {
      setDataFilter(missingActual);
    } else {
      let arr = [];
      for (let i = 0; i < missingActual?.length; i++) {
        let checkData = missingActual[i].missingMeasures.filter((item) => filterMeasureValue.includes(item));
        if (checkData?.length) {
          arr.push(missingActual[i]);
        }
      }
      setDataFilter(arr);
    }
  }, [filterMeasureValue, missingActual]);

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 2,
    });
  };

  const onSave = async (id: number, { comment, missingMeasures }: MissingActualResponse) => {
    try {
      await CreateCommentActual({ comment, teamId: id, dateid: filterDateId });
      const newData = updateItem({ comment, id, missingMeasures }, missingActual);
      setMissingActual(newData);
      notification.success({
        message: `Save Comment successfully`,
        duration: 2,
      });
    } catch (error) {
      openNotification(400, 'Update failed!');
    }
  };

  const onDelete = async (id: number, { comment, missingMeasures }: MissingActualResponse) => {
    try {
      await deleteComment({ dateId: filterDateId, teamId: id });
      comment = '';
      const newData = updateItem({ comment, id, missingMeasures }, missingActual);
      setMissingActual(newData);
      notification.success({
        message: `Delete Comment successfully`,
        duration: 2,
      });
    } catch (error) {
      openNotification(400, 'Delete failed!');
    }
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleMonthChangeds = (record) => {
    const months = validMonthsYears?.filter((element) => moment(element, DATE_ID_FORMAT).format('YYYY') === record);
    return months.map((month) => moment(month, DATE_ID_FORMAT).format('MMMM'));
  };

  const hexToRGB = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex !== null ? hex : '#ffffff');
    return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, 0.4)`;
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <>
      <Helmet>
        <title>HealthCheck</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Healthcheck</span>
          </div>
          <Row className="row">
            {mobileSize === false ? (
              <Col xxl={4} xl={4}>
                <Card className="filter-actions-section" title="">
                  <Space direction="vertical">
                    <Title level={4}>Healthcheck</Title>
                    {validYears?.map((element) => {
                      const year = moment(element, DATE_ID_FORMAT).format('YYYY');
                      const uniqueMonths = handleMonthChangeds(year);
                      return (
                        <Collapse ghost bordered={false} key={year} defaultActiveKey={element}>
                          <Panel key={element} header={<strong style={{ fontSize: '16px' }}>{year}</strong>}>
                            <Timeline>
                              {uniqueMonths?.map((month) => {
                                const monthFormated = moment(month, 'MMMM').format('MM');
                                return (
                                  <Timeline.Item key={year + monthFormated}>
                                    <Button
                                      style={{ color: year + monthFormated == filterDateId ? 'white' : 'black' }}
                                      onClick={() => handleMonthChanged(`${year + monthFormated}`)}
                                      type={year + monthFormated == filterDateId ? 'primary' : 'text'}
                                    >
                                      {month}
                                    </Button>
                                  </Timeline.Item>
                                );
                              })}
                            </Timeline>
                          </Panel>
                        </Collapse>
                      );
                    })}
                    <Title level={4}>Engagement</Title>
                    <Radio.Group onChange={onFilterChange} value={filterValue}>
                      <Space direction="vertical">
                        <Radio value={1}>All</Radio>
                        <Radio value={2}>Select a destination</Radio>
                      </Space>
                    </Radio.Group>
                    <StyledTeamSelect className="filter-searchbox">
                      <TeamSelect
                        type={['Engagement', 'Team']}
                        allowClear
                        showSearch
                        onChange={handleTeamChange}
                        value={selectedTeam}
                        disabled={filterDisabled}
                        placeholder="Search for project team"
                      />
                    </StyledTeamSelect>
                  </Space>
                </Card>
              </Col>
            ) : (
              <Col className="chart-Program" xxl={8} xl={8}>
                <Collapse accordion>
                  <Panel header="TimeLine" key="1">
                    <Card className="filter-actions-section" title="">
                      <Space direction="vertical">
                        <Title level={4}>Healthcheck</Title>
                        {validYears?.map((element) => {
                          const year = moment(element, DATE_ID_FORMAT).format('YYYY');
                          const uniqueMonths = handleMonthChangeds(year);
                          return (
                            <Collapse ghost bordered={false} key={year} defaultActiveKey={element}>
                              <Panel key={element} header={<strong style={{ fontSize: '16px' }}>{year}</strong>}>
                                <Timeline>
                                  {uniqueMonths?.map((month) => {
                                    const monthFormated = moment(month, 'MMMM').format('MM');
                                    return (
                                      <Timeline.Item key={year + month}>
                                        <Button
                                          style={{ color: year + monthFormated === filterDateId ? 'white' : 'black' }}
                                          onClick={() => handleMonthChanged(`${year + monthFormated}`)}
                                          type={year + monthFormated === filterDateId ? 'primary' : 'text'}
                                        >
                                          {month}
                                        </Button>
                                      </Timeline.Item>
                                    );
                                  })}
                                </Timeline>
                              </Panel>
                            </Collapse>
                          );
                        })}
                        <Title level={4}>Engagement</Title>
                        <Radio.Group onChange={onFilterChange} value={filterValue}>
                          <Space direction="vertical">
                            <Radio value={1}>All</Radio>
                            <Radio value={2}>Select a destination</Radio>
                          </Space>
                        </Radio.Group>
                        <StyledTeamSelect className="filter-searchbox">
                          <TeamSelect
                            type={['Engagement', 'Team']}
                            allowClear
                            showSearch
                            onChange={handleTeamChange}
                            value={selectedTeam}
                            disabled={filterDisabled}
                            placeholder="Search for project team"
                          />
                        </StyledTeamSelect>
                      </Space>
                    </Card>
                  </Panel>
                </Collapse>
              </Col>
            )}
            <Col xxl={20} xl={20}>
              {mobileSize === false ? (
                <Row className="btn-healthCheck">
                  {filterFormMeasure?.map((item, idx) => {
                    let btnOnCheck = filterMeasureValue.indexOf(item.title) > -1;
                    return (
                      <Button
                        className="btn-filter-measure"
                        style={
                          btnOnCheck
                            ? { backgroundColor: item.color, borderWidth: 2, borderColor: 'black' }
                            : { backgroundColor: hexToRGB(item.color) }
                        }
                        key={idx}
                        onClick={() => {
                          handleFilter(item);
                        }}
                      >
                        {item.title}
                      </Button>
                    );
                  })}
                </Row>
              ) : (
                <Collapse accordion>
                  <Panel header="Filter" key="1">
                    <Row className="btn-healthCheck">
                      {filterFormMeasure?.map((item, idx) => {
                        let btnOnCheck = filterMeasureValue.indexOf(item.title) > -1;
                        return (
                          <Button
                            className="btn-filter-measure"
                            style={
                              btnOnCheck
                                ? { backgroundColor: item.color, borderWidth: 2, borderColor: 'black' }
                                : { backgroundColor: hexToRGB(item.color) }
                            }
                            key={idx}
                            onClick={() => {
                              handleFilter(item);
                            }}
                          >
                            {item.title}
                          </Button>
                        );
                      })}
                    </Row>
                  </Panel>
                </Collapse>
              )}
              {loading && (
                <Spin
                  indicator={antIcon}
                  tip="loading..."
                  style={{ display: 'flex', justifyContent: 'center' }}
                  size="large"
                />
              )}
              <Row className="table-missing-measure">
                {datafilter && (
                  <EditableTable
                    columns={columns}
                    data={datafilter}
                    bordered
                    onChange={onChange}
                    onSave={onSave}
                    onDelete={onDelete}
                    scroll={{ x: '1000px' }}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};

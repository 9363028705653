import { KPIResponse, KPIResponseModel, KPIWeightResponse, KPIWeightModelResponse } from '../types/metrics';
import { fetchAsync } from '../utils/fetch';

export const fetchKPI = (): Promise<KPIResponse[]> => fetchAsync('/api/custommetric');

export const updateKPI = (id: number, metric: KPIResponseModel): Promise<KPIResponse> =>
  fetchAsync(`/api/custommetric/${id}`, {
    method: 'PUT',
    body: metric,
  });

export const deleteKPI = (id: number): Promise<void> =>
  fetchAsync(`/api/customMetric/${id}`, {
    method: 'DELETE',
  });

export const createKPI = (metric: KPIResponseModel): Promise<KPIResponse> =>
  fetchAsync(`/api/custommetric`, {
    method: 'POST',
    body: metric,
  });

export const fetchKPIByteam = (DateId: number, teamId: number, correctMonth: boolean): Promise<KPIWeightResponse[]> =>
  fetchAsync(`/api/projectspecificmetric/${teamId}/dateId/correctMonth?dateId=${DateId}&correctMonth=${correctMonth}`, {
    method: 'GET',
    type: 'blob',
  });

export const createKPIWeight = (
  dateId: number,
  teamId: number,
  categoryId: number,
  KpiWeight: KPIWeightModelResponse[]
): Promise<void> =>
  fetchAsync(`/api/weight/KPI-Weight/${dateId}/${teamId}/${categoryId}`, {
    method: 'POST',
    body: KpiWeight,
  });

import { Form, Input, Button, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ResetPassword } from '../components/ResetPassword';
import { UserResponse } from '../types/user';
import { fetchUsersById } from '../apis/userClient';

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

export const Profile = () => {
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState<UserResponse>(null);
  const [error, setError] = useState(null);
  const {
    user: { id },
  } = useContext(AuthContext);

  useEffect(() => {
    (async function () {
      try {
        const result = await fetchUsersById(id);
        setUserData(result);
      } catch (error) {
        setError(error);
      }
    })();
  }, [id]);

  if (userData) {
    const options = Object.keys(userData.roles).map((key) => userData.roles[key]);
    return (
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container" style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center', width: '600px', display: 'inline-block' }}>
              <span className="section-title">My profile</span>
              <div className="form" style={{ paddingTop: '20px' }}>
                <Form name="basic" colon={false} {...layout}>
                  <Form.Item label="Full name" name="fullname" labelAlign="left" style={{ fontWeight: 'bold' }}>
                    <Input disabled defaultValue={userData?.fullName} />
                  </Form.Item>
                  <Form.Item label="Username" name="username" labelAlign="left" style={{ fontWeight: 'bold' }}>
                    <Input disabled defaultValue={userData?.userName} />
                  </Form.Item>
                  <Form.Item label="Role" name="role" labelAlign="left" style={{ fontWeight: 'bold' }}>
                    <Select disabled mode="multiple" style={{ width: '100%' }} defaultValue={options} />{' '}
                  </Form.Item>
                  <Form.Item label="Email" name="email" labelAlign="left" style={{ fontWeight: 'bold' }}>
                    <Input disabled defaultValue={userData?.email} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="link" htmlType="button" onClick={() => setVisible(true)}>
                      Reset password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
          <ResetPassword id={id} isOpenDialog={visible} setVisible={setVisible} />
        </section>
      </div>
    );
  } else {
    return <div>{JSON.stringify(error)}</div>;
  }
};
export default Profile;

import styled from 'styled-components';

import './NoteDrawer.scss';

export const StyledTabs = styled.div`
  .ant-tabs-nav-list {
    .ant-tabs-tab-btn {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 350px;
    }
  }

  .ant-tabs-content {
    padding: 1rem 2rem;

    &.ant-tabs-content-top {
      .section-title {
        margin-bottom: 1rem;
      }
      .filter-row {
        margin-bottom: 1.5rem;
        align-items: center;
        &_btn {
          text-align: right;
        }
        &_element {
          text-align: left;
          .section-title {
            margin: 0;
            text-transform: none;
            font-weight: 37.5rem;
          }
        }
      }
      .form-group {
        &_btn-group {
          .form-btn:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
      .ant-btn.ant-btn-link {
        padding: 0;
        margin-bottom: 1rem;
        &.filter-row_btn {
          margin-bottom: 0;
        }
      }
    }
  }

  .note-container {
    width: 100%;
    h5 {
      margin-bottom: 1.5rem;
    }
    .ant-row {
      &.msg-container {
        background: whitesmoke;
        padding: 1rem 2rem;
        margin-bottom: 0.5rem;
      }
      .btn-group-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export const StyledTeamSelect = styled.div`
  .ant-select {
    width: 100%;
  }
`;

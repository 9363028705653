import { Spin } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { fetchGuideline } from '../apis/guidelineClient';
import { useFetch } from '../hooks/useFetch';
import { GuidelineResponse } from '../types/guideline';

interface IGuidelineContext {
  guideline: GuidelineResponse;
  setGuideline: (guideline: GuidelineResponse) => void;
}

export const GuidelineContext = createContext<IGuidelineContext>(null);

interface IProps {
  children: React.ReactNode;
}

export const GuidelineContextProvider = ({ children }: IProps) => {
  const [guideline, setGuideline] = useState<GuidelineResponse>();
  const { data: originalData, error: fetchError } = useFetch(fetchGuideline);

  useEffect(() => {
    setGuideline(originalData);
  }, [originalData]);

  if (fetchError) {
    return <div>{JSON.stringify(fetchError)}</div>;
  }

  if (!guideline) {
    return <Spin />;
  }

  return <GuidelineContext.Provider value={{ guideline, setGuideline }}>{children}</GuidelineContext.Provider>;
};

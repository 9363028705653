import { Typography } from 'antd';
import { Upload, Button, DatePicker, Table, Alert } from 'antd';
import { useState } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import downloadFileFromBlob from '../../utils/downloadFileFromBlob';
import { ImportBody, ImportContent, ImportHeader, ImportLayout } from '../../layouts/ImportLayout.styled';
import { PageTitle } from '../../layouts/AdminLayout.styled';
import fetchTeamWeightTemplate, { postTeamWeightTemplate } from '../../apis/fetchImportTeamWeight';

const { Title } = Typography;

const columns = [
  {
    title: 'Sheet Name',
    dataIndex: 'members',
    width: '200px',
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
];

export const TeamWeight = () => {
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState();
  const [file, setFile] = useState();
  const [isDisableUpload, setIsDisableUpload] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [listErrors, setListErrors] = useState();
  const [dateId, setDateId] = useState<number>();
  const [isDisableBrowse, setIsDisableBrowse] = useState<boolean>(true);
  const setSelectedFile = (selectedFile) => {
    setFile(selectedFile);
    setListErrors(null);
    setIsDisableUpload(false);
    return false;
  };

  const handleUpload = async () => {
    try {
      setIsLoadingUpload(true);
      setIsOpen(false);
      setIsDisableUpload(true);
      setListErrors(null);
      const result = await postTeamWeightTemplate(dateId, file);
      setIsOpen(true);
    } catch (error) {
      if (error.data.error.validationErrors !== null) {
        let err = error.data.error.validationErrors?.map((e) => e);
        setListErrors(err);
        console.log(err);
      } else {
        alert('Failed! Please correct the file.');
      }
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const downloadFile = async () => {
    try {
      setIsLoadingDownload(true);
      const result = await fetchTeamWeightTemplate(dateId);
      downloadFileFromBlob(result.blob, result.fileName);
      setDownloadError(null);
    } catch (error) {
      setDownloadError(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  const handleMonthPicker = (month) => {
    if (month !== null) {
      const monthPicker = month.format().replace('-', '').slice(0, 6);
      setDateId(monthPicker);
      setIsDisableBrowse(false);
    }
  };

  return (
    <ImportLayout>
      <Helmet>
        <title>Import Team Weight</title>
      </Helmet>
      <ImportHeader>
        <PageTitle>
          <Title level={3}>Import Team Weight</Title>
        </PageTitle>
      </ImportHeader>
      <ImportBody>
        <ImportContent>
          <Title className="title" style={{ fontWeight: 'normal' }} level={5}>
            Click here to download the latest import template
          </Title>
          <Button
            size="large"
            type="primary"
            onClick={downloadFile}
            loading={isLoadingDownload}
            disabled={isDisableBrowse}
          >
            Download
          </Button>
          {downloadError && <div>{JSON.stringify(downloadError)}</div>}
        </ImportContent>
        <Title level={4}>Bulk upload Team Weight</Title>
        <ImportContent>
          <Title className="title" level={5}>
            Select a month <p className={classNames('month-picker', { 'has-value': dateId })}>*</p>
          </Title>
          <DatePicker picker="month" style={{ marginRight: '30px' }} onChange={handleMonthPicker} allowClear={false} />
        </ImportContent>
        <Upload beforeUpload={setSelectedFile} accept=".xlsx" maxCount={1}>
          <Button size="large" type="primary" style={{ marginRight: '20px' }} disabled={isDisableBrowse}>
            Browse
          </Button>
        </Upload>
        <Button
          size="large"
          type="primary"
          onClick={handleUpload}
          disabled={isDisableUpload}
          loading={isLoadingUpload}
          style={{ marginTop: '20px' }}
        >
          {isLoadingUpload ? 'Uploading' : 'Start Upload'}
        </Button>
        {isOpen && <Alert closable message="Successfully" type="success" showIcon />}
        {listErrors && (
          <Table
            className="table-error"
            scroll={{ y: 300 }}
            columns={columns}
            dataSource={listErrors}
            pagination={false}
          />
        )}
      </ImportBody>
    </ImportLayout>
  );
};

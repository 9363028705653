import { round, sumBy } from 'lodash';
import { ColorLabelContainer, StyledColorGradient, StyledColorLabel, StyledEndLabel } from './ColorScale.styled';

export interface IColorScale {
  value: number;
  color: string;
}

interface IProps {
  scales: IColorScale[];
}

interface IColorGradient {
  startColor: string;
  endColor: string;
  start: number;
  end: number;
  lastItem?: boolean;
  length: number;
}

export const ColorScale = ({ scales }: IProps) => {
  const ranges: IColorGradient[] = [];
  for (let index = 0; index < scales.length - 1; index++) {
    const curr = scales[index];
    const next = scales[index + 1];
    const length = curr.value - next.value;
    if (length) {
      ranges.push({
        startColor: curr.color,
        endColor: next.color,
        start: curr.value,
        end: next.value,
        lastItem: index === scales.length - 2,
        length,
      });
    }
  }

  const totalLength = sumBy(ranges, (item) => item.length);

  return (
    <div className="color-scale">
      {ranges.map((item, idx) => (
        <StyledColorGradient
          key={idx}
          style={{
            background: `linear-gradient(to right, ${item.startColor}, ${item.endColor})`,
            width: `${round((item.length / totalLength) * 100, 2)}%`,
          }}
        />
      ))}
      <ColorLabelContainer>
        {ranges.map((item, idx) => (
          <StyledColorLabel
            key={idx}
            lastItem={item.lastItem}
            style={{
              width: `${round((item.length / totalLength) * 100, 2)}%`,
            }}
          >
            <span>{item.start}</span>
            {item.lastItem && <StyledEndLabel>{item.end}</StyledEndLabel>}
          </StyledColorLabel>
        ))}
      </ColorLabelContainer>
    </div>
  );
};

import { Spin } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { fetchTeams } from '../apis/teamClient';
import { useFetch } from '../hooks/useFetch';
import { TeamResponse } from '../types/team';

interface ITeamContext {
  teams: TeamResponse[];
  setTeams: (teams: TeamResponse[]) => void;
}

export const TeamContext = createContext<ITeamContext>({ teams: [], setTeams: () => {} });

interface IProps {
  children: React.ReactNode;
}

export const TeamContextProvider = ({ children }: IProps) => {
  const [teams, setTeams] = useState<TeamResponse[]>();
  const { data: originalData, error: fetchError } = useFetch(fetchTeams);

  useEffect(() => {
    setTeams(originalData);
  }, [originalData]);

  if (fetchError) {
    return <div>{JSON.stringify(fetchError)}</div>;
  }

  if (!teams) {
    return <Spin />;
  }

  return <TeamContext.Provider value={{ teams, setTeams }}>{children}</TeamContext.Provider>;
};

import { Bar } from '@ant-design/charts';
import { NpsDataContext } from '../contexts/NpsDataContext';
import { useContext } from 'react';
import { Empty } from 'antd';
import { getNpsCategory, getNpsColor } from '../utils/chartUtils';
import { orderBy } from 'lodash';
import { BarConfig } from '@ant-design/charts/es';

type TeamType = 'Engagement' | 'Team';

interface IProps {
  type: TeamType;
}

export const EngagementNpsColumn = ({ type }: IProps) => {
  const { engagmentActuals, teamActuals } = useContext(NpsDataContext);

  const actualsNotNull =
    type === 'Engagement'
      ? engagmentActuals?.filter((item) => item.nps != null)
      : teamActuals?.filter((item) => item.nps != null);

  if (!actualsNotNull?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  let data = orderBy(actualsNotNull, (item) => item.nps, 'asc').map((item) => ({
    value: item.nps,
    label: item.name,
    category: getNpsCategory(item.nps),
  }));

  const handleBarClicked = (_, event) => {
    if (event.type !== 'axis-label:click') {
      return;
    }

    const target: any = event.target;
    const team = actualsNotNull.find((x) => x.name === target.attrs.text);

    window.open(`/${type === 'Engagement' ? type : 'project'}/${team.teamId}`);
  };

  var config: BarConfig = {
    height: 400,
    seriesField: 'category',
    yField: 'label',
    xField: 'value',
    color: ({ category }) => {
      const label = category.split(' (')[0];
      return getNpsColor(label);
    },
    label: {},
    data,
    legend: {
      position: 'top',
      flipPage: false,
    },
    xAxis: { label: { autoRotate: true } },
    scrollbar: {
      type: 'vertical',
    },
    onEvent: handleBarClicked,
  };

  return <Bar {...config} />;
};

export const ACCESS_TOKEN_KEY = 'access_token';
export const DATE_ID_FORMAT = 'YYYYMM';
export const MONTH_PICKER_FORMAT = 'MMM YYYY';
export const MONTH_FORMAT = 'MMM';
export const MONTH_YEAR_FORMAT = 'MMM YY';
export const MIN_DATE_ID = new Date(2020, 0);
export const BELOW_TARGET = 0.75;
export const ABOVE_TARGET = 0.9;
export const GAUGE_DETRACTOR = 0.5;
export const GAUGE_PROMOTER = 0.65;
export const GAUGE_EXCELLENT = 0.85;
export const PROMOTER_LABEL = 'Promoters';
export const DETRACTOR_LABEL = 'Detractors';
export const PASSIVE_LABEL = 'Passives';
export const SENTIMENT_POS_LABEL = 'Good';
export const SENTIMENT_NEUTRAL_LABEL = 'Neutral';
export const SENTIMENT_NEG_LABEL = 'Not Good';

export const COLOR_BELOW_TARGET = '#d82b22';
export const COLOR_ON_TARGET = '#80b0ff';
export const COLOR_ABOVE_TARGET = '#0058e9';
export const CELL_COLOR_BELOW_TARGET = '#ffc7ce';
export const CELL_COLOR_ON_TARGET = '#bdd6ff';
export const CELL_COLOR_ABOVE_TARGET = '#5e9bff';
export const COLOR_NPS_STATUS = '#9cc57a';
export const COLOR_PASSIVE = '#808080';
export const PROMOTER_COLOR = '#0cc187';
export const DETRACTOR_COLOR = '#ef464a';
export const PASSIVE_COLOR = '#fab349';
export const EXCELLENT_COLOR = '#1a8143';
export const SENTIMENT_POS_COLOR = '#0ba800';
export const SENTIMENT_NEUTRAL_COLOR = '#f3c300';
export const SENTIMENT_NEG_COLOR = '#ff7272';
export const SENTIMENT_NEG_CEll_COLOR = '#ffc8c8';
export const SENTIMENT_POS_CELL_COLOR = '#3cb371';
export const SENTIMENT_NEUTRAL_CELL_COLOR = '#f6deb9';

export const PROMOTER_SCORE = 8;
export const DETRACTOR_SCORE = 7;

export const BELOW_TARGET_LABEL = 'Below Target';
export const ON_TARGET_LABEL = 'On Target';
export const ABOVE_TARGET_LABEL = 'Above Target';
export const COLOR_PRIMARY = '#024fa5';

export const GOOD_SENTIMENT = 'GOOD';
export const BAD_SENTIMENT = 'NOT GOOD';

export const DUPLICATE_TEAM_MESSAGE = 'Team name already exist.';
export const GENERIC_MESSAGE = 'There has been an error processing your request.';

export const USER_PAGE_NUMBER = 20;

export const ROLE = { CAPG: 'capg', ADMIN: 'admin', USER: 'user' };

export const Category = 'Category';
export const Sentiment = 'Sentiment';

export const Themes = {
  CodingPerformance: 'CodingPerformance',
  Financial: 'Financial',
  People: 'People',
  Relationship: 'Relationship',
  Risk: 'Risk',
};

import { Typography } from 'antd';
import { Upload, Button, DatePicker, Table, Alert } from 'antd';
import { useState } from 'react';
import classNames from 'classnames';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import {
  ImportBody,
  ImportDescription,
  ImportContent,
  ImportHeader,
  ImportLayout,
} from '../layouts/ImportLayout.styled';
import { PageTitle } from '../layouts/AdminLayout.styled';
import { Helmet } from 'react-helmet';
import { fetchSentimentTemplate, postSentimentTemplate } from '../apis/fetchsentiment';
import './ImportSentiment.scss';

const { Title } = Typography;

const columns = [
  {
    title: 'Message',
    dataIndex: 'message',
  },
];

export const ImportSentiment = () => {
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState();
  const [file, setFile] = useState();
  const [isDisableUpload, setIsDisableUpload] = useState<boolean>(true);
  const [isDisableBrowse, setIsDisableBrowse] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateId, setDateId] = useState<number>();
  const [listErrors, setListErrors] = useState();

  const handleMonthPicker = (month) => {
    if (month !== null) {
      const monthPicker = month.format().replace('-', '').slice(0, 6);
      setDateId(monthPicker);
      setIsDisableBrowse(false);
    }
  };

  const setSelectedFile = (selectedFile) => {
    setFile(selectedFile);
    setListErrors(null);
    setIsDisableUpload(false);
    return false;
  };

  const handleUpload = async () => {
    try {
      setIsLoadingUpload(true);
      setIsOpen(false);
      setIsDisableUpload(true);
      setListErrors(null);
      const result = await postSentimentTemplate(file, dateId);
      setIsOpen(true);
    } catch (error) {
      if (error.data.error.validationErrors !== null) {
        let err = error.data.error.validationErrors.map((e) => e);
        setListErrors(err);
      } else {
        alert('Failed! Please correct the file.');
      }
    } finally {
      setIsLoadingUpload(false);
    }
  };

  const downloadFile = async () => {
    try {
      setIsLoadingDownload(true);
      const result = await fetchSentimentTemplate();
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
      setDownloadError(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  return (
    <ImportLayout>
      <Helmet>
        <title>Import Sentiment</title>
      </Helmet>
      <ImportHeader>
        <PageTitle>
          <Title level={3}>Import Monthly Sentiment</Title>
        </PageTitle>
      </ImportHeader>
      <ImportBody>
        <ImportContent>
          <ImportDescription>Click here to download the latest import template</ImportDescription>
          <Button size="large" type="primary" onClick={downloadFile} loading={isLoadingDownload}>
            Download
          </Button>
          <div>{JSON.stringify(downloadError)}</div>
        </ImportContent>
        <Title level={4}>Bulk upload Monthly Sentiment</Title>
        <ImportContent>
          <Title className="title" level={5}>
            Select a month <p className={classNames('month-picker', { 'has-value': dateId })}>*</p>
          </Title>
          <DatePicker
            className="datepicker-import-sentiment"
            picker="month"
            onChange={handleMonthPicker}
            allowClear={false}
          />
        </ImportContent>
        <Upload beforeUpload={setSelectedFile} accept=".xlsx" maxCount={1}>
          <Button className="btn-browse" size="large" type="primary" disabled={isDisableBrowse}>
            Browse
          </Button>
        </Upload>
        <Button
          className="btn-upload"
          size="large"
          type="primary"
          onClick={handleUpload}
          disabled={isDisableUpload}
          loading={isLoadingUpload}
        >
          {isLoadingUpload ? 'Uploading' : 'Start Upload'}
        </Button>
        {isOpen && <Alert closable message="Successfully" type="success" showIcon />}
        {listErrors && (
          <Table
            className="table-error"
            scroll={{ y: 300 }}
            columns={columns}
            dataSource={listErrors}
            pagination={false}
          />
        )}
      </ImportBody>
    </ImportLayout>
  );
};

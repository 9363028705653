import { Empty } from 'antd';
import { orderBy } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';

import { ProgramTrendingDataContext } from '../../contexts/ProgramTrendingDataContext';
import { DATE_ID_FORMAT } from '../../common/constants';
import { Column } from '@ant-design/charts';
import { IProgramTrendingData } from '../../types/metrics';
import { ColumnConfig } from '@ant-design/charts/es';

export const DepartmentNPSTreding = () => {
  const { departmentDatas } = useContext<IProgramTrendingData>(ProgramTrendingDataContext);

  const data = orderBy(departmentDatas, (item) => item.dateId).map((item) => ({
    month: moment(item.dateId, DATE_ID_FORMAT).format('MMM YY'),
    key: item.name,
    value: item.npsPerformance,
  }));
  const config: ColumnConfig = {
    data: data,
    xField: 'month',
    yField: 'value',
    isGroup: true,
    seriesField: 'key',
    legend: {
      position: 'top',
      flipPage: false,
    },
  };
  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else {
    return <Column {...config} />;
  }
};

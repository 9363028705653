import { createContext, useContext } from 'react';
import moment from 'moment';
import { useFetch } from '../hooks/useFetch';
import { MeasureQuery, ScoreQuery, SentimentNpsRequest } from '../types/metrics';
import { DATE_ID_FORMAT } from '../common/constants';
import { Spin } from 'antd';
import { TeamContext } from './TeamContext';
import { npsTransformData } from '../utils/npsData';
import { fetchNPSData } from '../apis/scoreClient';
import { fetchSentimentNPSChart } from '../apis/fetchsentiment';
import { INpsData } from '../types/nps';

export const NpsDataContext = createContext<INpsData>(null);

interface IProps {
  month: Date;
  children: React.ReactNode;
}

export const NpsDataContextProvider = ({ month, children }: IProps) => {
  const { teams } = useContext(TeamContext);
  let endDateId = month ? moment(month).format(DATE_ID_FORMAT) : null;
  let measureQuery: MeasureQuery;
  const teamTypes = ['Program', 'Department'];

  if (month) {
    const startDateId = moment(month).clone().subtract(1, 'month').format(DATE_ID_FORMAT);
    measureQuery = {
      startDateId,
      endDateId,
    };
  } else {
    measureQuery = {
      monthsLimit: 2,
    };
  }

  const scoreQuery: ScoreQuery = {
    ...measureQuery,
    teamTypes: teamTypes,
  };

  const sentimentQuery: SentimentNpsRequest = {
    ...measureQuery,
  };

  const { data: npsData, loading, error } = useFetch(() => fetchNPSData(scoreQuery), [month]);

  const { data: sentimentNpsData } = useFetch(() => {
    return fetchSentimentNPSChart(sentimentQuery);
  }, [month]);

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <Spin spinning={loading}>
      <NpsDataContext.Provider value={npsTransformData(npsData, teams, sentimentNpsData, endDateId)}>
        {children}
      </NpsDataContext.Provider>
    </Spin>
  );
};

import {
  MeasureResponse,
  PageResult,
  ProjectMetricFactRequest,
  ProjectMetricFactResponse,
  TargetRequestPaging,
} from '../types/metrics';
import { TargetModel, TeamTarget } from '../types/target';
import { fetchAsync } from '../utils/fetch';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(query);
  return urlParams.toString();
};

export const fetchTargets = (query: TargetRequestPaging): Promise<PageResult<MeasureResponse>> =>
  fetchAsync(`/api/target?${toUrlSearchParams(query)}`);

export const fetchTargetsByTeamId = (query: any): Promise<MeasureResponse[]> =>
  fetchAsync(`/api/target/search?${toUrlSearchParams(query)}`);

export const createTarget = (target: TargetModel): Promise<MeasureResponse> =>
  fetchAsync(`/api/target`, {
    method: 'POST',
    body: target,
  });

export const updateTarget = (id: number, target: TargetModel): Promise<MeasureResponse> =>
  fetchAsync(`/api/target/${id}`, {
    method: 'PUT',
    body: target,
  });

export const deleteTarget = (dateId: number, teamId: number): Promise<void> =>
  fetchAsync(`/api/target/${dateId}/${teamId}`, {
    method: 'DELETE',
  });

export const fetchProjectMetricFact = (): Promise<TeamTarget[]> => fetchAsync('/api/projectmetricfact/targets');

export const updateProjectMetricFact = (
  teamId,
  dateId,
  projectMetricFacts: ProjectMetricFactRequest
): Promise<ProjectMetricFactResponse> =>
  fetchAsync(`/api/projectmetricfact/${teamId}/${dateId}`, {
    method: 'PUT',
    body: projectMetricFacts,
  });

export const deleteProjectMetricFact = (teamId, dateId): Promise<void> =>
  fetchAsync(`/api/projectmetricfact/${teamId}/${dateId}`, {
    method: 'DELETE',
  });

import { AppstoreOutlined, LoadingOutlined, RiseOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  InputNumber,
  Menu,
  MenuProps,
  Modal,
  Row,
  Spin,
  Table,
  TablePaginationConfig,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { createKPIWeight, fetchKPI, fetchKPIByteam } from '../../apis/customMetric';
import { createCategoryWeight, fetchCategory, fetchCategoryByteam } from '../../apis/fetchCategory';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../../common/constants';
import { EditableColumn } from '../../components/EditableTable';
import { TeamContext } from '../../contexts/TeamContext';
import { useFetch } from '../../hooks/useFetch';
import { PageTitle } from '../../layouts/AdminLayout.styled';
import { ImportContent } from '../../layouts/ImportLayout.styled';
import {
  CategoryWeightModel,
  CategoryWeightModelResponse,
  CategoryWeightResponse,
  KPIWeightModel,
  KPIWeightModelResponse,
  KPIWeightResponse,
} from '../../types/metrics';
import { updateItem } from '../../utils/tableUtils';
import './ModifyTeamWeight.scss';
interface RouteParams {
  projectId: string;
}
export const ModifyTeamWeight = () => {
  const { projectId } = useParams<RouteParams>();
  const [categoriesData, setCategoriesData] = useState<CategoryWeightResponse[]>();
  const [categoriesModel, setcategoriesModel] = useState<CategoryWeightModel[]>();
  const [categoriesModelResponse, setCategoriesModelResponse] = useState<CategoryWeightModelResponse[]>();
  const { data: originalCategories } = useFetch(fetchCategory);
  const { data: originalKPIs } = useFetch(fetchKPI);
  const [kpisData, setKPIsData] = useState<KPIWeightResponse[]>();
  const [kpisModel, setKpisModel] = useState<KPIWeightModel[]>();
  const [kpisModelResponse, setKpisModelResponse] = useState<KPIWeightModelResponse[]>();
  const { teams } = useContext(TeamContext);
  const [current, setCurrent] = React.useState('sub1');
  const [page, setPage] = useState(1);
  const [activeKPI, setactiveKPI] = useState<boolean>(false);
  const [visibleModalCategory, setVisibleModalCategory] = useState<boolean>(false);
  const [visibleModalKPI, setVisibleModalKPI] = useState<boolean>(false);
  const [disableSaveCategory, setDisableSaveCategory] = useState<boolean>(true);
  const [disableSaveKPI, setDisableSaveKPI] = useState<boolean>(true);
  const [disableAddCategory, setDisableAddCategory] = useState<boolean>(true);
  const [mode, setMode] = useState<boolean>(true);
  const [isDividedCategory, setIsDividedCategory] = useState<boolean>(false);
  const [isDividedKPI, setIsDividedKPI] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();
  const [date, setDate] = useState<number>(0);
  const team = teams.find((item) => item.id === parseInt(projectId));
  const engagement = teams.find((item) => item.id === team?.parentId);
  const { Panel } = Collapse;
  const handleClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key.toString());
    setMode(e.key.toString() === 'sub1' ? true : false);
  };
  const month = date ? moment(`${date}`, DATE_ID_FORMAT) : null;
  const getCategoriesData = async (teamId: number) => {
    try {
      let categories = await fetchCategoryByteam(date, teamId);
      setDate(categories[0]?.dateId);
      setCategoriesData(categories ? categories : []);
    } catch (error) {
      console.error(error);
    }
  };
  const getKPIsData = async (teamId: number) => {
    try {
      let kpis = await fetchKPIByteam(date, teamId, true);
      setKPIsData(kpis ? kpis : []);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setDate(0);
    getCategoriesData(Number(projectId));
    getKPIsData(Number(projectId));
  }, [projectId]);

  useEffect(() => {
    setactiveKPI(categoriesData?.length === 0 ? true : false);
  }, [categoriesData]);

  useEffect(() => {
    setcategoriesModel(
      originalCategories?.map((item) => ({
        id: item.id,
        name: item.name,
        weight: categoriesData?.filter((x) => x.name === item.name)[0]?.weight,
        checked: categoriesData?.filter((x) => x.name === item.name)[0] ? true : false,
      }))
    );
  }, [originalCategories, categoriesData]);

  const columnCategories: EditableColumn<any>[] = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      width: '40%',
      rules: [{ required: true, message: 'This field is required' }],
    },
    {
      title: 'Rate',
      dataIndex: 'weight',
      rules: [{ required: true, message: 'This field is required' }],
      editable: true,
    },
  ];
  const columnWeightCategories: EditableColumn<CategoryWeightModel>[] = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      width: '40%',
      key: 'name',
      rules: [{ required: true, message: 'This field is required' }],
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      inputType: 'number',
      inputProps: 'Weight',
      key: 'weight',
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => (
        <InputNumber
          style={{ width: 200 }}
          value={record?.weight}
          disabled={!record?.checked}
          step="0.1"
          onChange={(e) => handleChangeWeightCategory(record?.id, e)}
        />
      ),
    },
    {
      title: 'Checked',
      key: 'checked',
      dataIndex: 'checked',
      editable: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => (
        <Checkbox checked={record?.checked} onChange={() => handleChangeCheckCategory(record)} />
      ),
    },
  ];
  const columnKpis: EditableColumn<any>[] = [
    {
      title: 'KPI Name',
      dataIndex: 'name',
      width: '50%',
      rules: [{ required: true, message: 'This field is required' }],
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      rules: [{ required: true, message: 'This field is required' }],
      editable: true,
    },
  ];
  const columnWeightKPI: EditableColumn<KPIWeightModel>[] = [
    {
      title: 'KPI Name',
      dataIndex: 'name',
      width: '40%',
      rules: [{ required: true, message: 'This field is required' }],
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      inputType: 'number',
      editable: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => (
        <InputNumber
          style={{ width: 200 }}
          value={record?.weight}
          disabled={!record?.checked}
          step="0.1"
          onChange={(e) => handleChangeWeightKPI(record?.id, e)}
        />
      ),
    },
    {
      title: 'Checked',
      dataIndex: 'checked',
      inputType: 'checkbox',
      editable: true,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => (
        <Checkbox checked={record?.checked} onChange={() => handleChangeCheckKPI(record)} />
      ),
    },
  ];
  const onChange = (pagination: TablePaginationConfig, record: CategoryWeightModel) => {
    setPage(pagination.current);
  };

  const onSubmitModalCategory = async () => {
    var categories = categoriesModel.filter((item) => item.checked === true);
    setCategoriesModelResponse(
      categories?.map((item) => ({
        categoryId: item.id,
        weight: item.weight,
      }))
    );
    setLoading(true);
  };

  useEffect(() => {
    CreatedCategoriesWeight();
  }, [categoriesModelResponse]);

  const CreatedCategoriesWeight = async () => {
    try {
      await createCategoryWeight(date, Number(projectId), categoriesModelResponse);
      setVisibleModalCategory(false);
      setHasError('');
      setCategoriesData(
        categoriesModel
          .filter((item) => item.checked === true)
          ?.map((item) => ({
            teamId: Number(projectId),
            name: item.name,
            weight: item.weight,
            themeColor: '',
            dateId: date,
          }))
      );
      setDisableSaveCategory(true);
      setLoading(false);
    } catch (error) {
      if (categoriesModelResponse) setHasError(error.data.error?.message);
    }
  };

  const getKpis = (record: KPIWeightResponse[], categories: CategoryWeightResponse) => {
    var result = record?.filter((item) => item.categoryName === categories.name);
    return result;
  };
  const handleClickCategory = () => {
    setVisibleModalCategory(true);
  };
  const handleChangeWeightCategory = (id: number, weight: number) => {
    setDisableSaveCategory(false);
    var newdata = updateItem({ id, weight }, categoriesModel);
    setcategoriesModel(newdata);
  };

  const handleChangeCheckCategory = ({ id, name, weight, checked }: CategoryWeightModel) => {
    setDisableSaveCategory(false);
    checked = !checked;
    var newdata = updateItem({ id, name, weight, checked }, categoriesModel);
    setcategoriesModel(newdata);
  };

  const handleChangeWeightKPI = (id: number, weight: number) => {
    setDisableSaveKPI(false);
    var newdata = updateItem({ id, weight }, kpisModel);
    setKpisModel(newdata);
  };
  const handleChangeCheckKPI = ({ id, name, categoryId, weight, checked }: KPIWeightModel) => {
    setDisableSaveKPI(false);
    checked = !checked;
    var newdata = updateItem({ id, name, categoryId, weight, checked }, kpisModel);
    setKpisModel(newdata);
  };
  const handleClickKPI = (categoryId: number) => {
    setKpisModel(
      originalKPIs
        ?.filter((f) => f.categoryId === categoryId)
        .map((item) => ({
          id: item.id,
          name: item.name,
          categoryId: item.categoryId,
          weight: kpisData?.filter((x) => x.name === item.name)[0]?.weight,
          checked: kpisData?.filter((x) => x.name === item.name)[0] ? true : false,
        }))
    );
    setVisibleModalKPI(true);
  };

  const onSubmitModalKPI = async () => {
    var kpis = kpisModel.filter((item) => item.checked === true);
    setKpisModelResponse(
      kpis?.map((item) => ({
        metricId: item.id,
        categoryId: item.categoryId,
        weight: item.weight,
      }))
    );
    setLoading(true);
  };
  const handleChangeDividedCategory = (e: boolean) => {
    setIsDividedCategory(e);
    if (e === true) {
      var count = categoriesModel?.filter((item) => item.checked === true)?.length;
      categoriesModel
        ?.filter((item) => item.checked === true)
        ?.forEach((item) => (item.weight = Number((1 / count).toFixed(5))));
      setDisableSaveCategory(false);
    }
  };
  const handleChangeDividedKPI = (e: boolean) => {
    setIsDividedKPI(e);
    if (e === true) {
      var count = kpisModel?.filter((item) => item.checked === true)?.length;
      kpisModel
        ?.filter((item) => item.checked === true)
        ?.forEach((item) => (item.weight = Number((1 / count).toFixed(5))));
      setDisableSaveKPI(false);
    }
  };
  const onChangeCollapseKPI = () => {};

  useEffect(() => {
    CreatedKpisWeight();
  }, [kpisModelResponse]);

  const CreatedKpisWeight = async () => {
    try {
      await createKPIWeight(date, Number(projectId), kpisModelResponse[0].categoryId, kpisModelResponse);
      setVisibleModalKPI(false);
      setHasError('');
      getKPIsData(Number(projectId));
      setDisableSaveKPI(true);
      setLoading(false);
    } catch (error) {
      if (kpisModelResponse) setHasError(error.data.error?.message);
    }
  };

  const handleMonthPicker = (month) => {
    if (month !== null) {
      const monthPicker = month.format().replace('-', '').slice(0, 6);
      setDate(monthPicker);
      setDisableAddCategory(false);
    }
  };

  useEffect(() => {
    const fetchDataCategory = async () => {
      try {
        let categories = await fetchCategoryByteam(date, Number(projectId));
        setCategoriesData(categories ? categories : []);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDataKPI = async () => {
      try {
        let kpis = await fetchKPIByteam(date, Number(projectId), true);
        setKPIsData(kpis ? kpis : []);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataKPI();
    fetchDataCategory();
  }, [date]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="page-home page-container">
      <section className="section-container">
        <div className="heatmap-container">
          <Row>
            <Col xs={24} xl={3}>
              <PageTitle>
                <Title level={3}>KPI Management</Title>
              </PageTitle>
            </Col>
            <ImportContent>
              <DatePicker
                value={month}
                picker="month"
                disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
                style={{ marginRight: '30px' }}
                format={MONTH_PICKER_FORMAT}
                onChange={handleMonthPicker}
              />
            </ImportContent>
          </Row>
          <Row>
            <Breadcrumb className="breadcumb-modifyWeight" separator=">">
              <Breadcrumb.Item>{engagement.name}</Breadcrumb.Item>
              <Breadcrumb.Item>{team.name}</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row className="weight">
            <Col xs={24} xl={4}>
              <Menu className="menu-teamweight" onClick={handleClick} mode="vertical" selectedKeys={[current]}>
                <Menu.Item key="sub1" icon={<AppstoreOutlined />} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
                  Category
                </Menu.Item>
                <Menu.Item
                  key="sub2"
                  icon={<RiseOutlined />}
                  style={{ fontWeight: 'bold', fontSize: 'medium' }}
                  disabled={activeKPI}
                >
                  KPI
                </Menu.Item>
              </Menu>
            </Col>
            <Col xs={24} xl={20}>
              <div className="weight-configuration">
                {mode ? (
                  <>
                    <Title level={3} style={{ fontWeight: 'bold' }}>
                      CATEGORY CONFIGURATION
                    </Title>
                    <div className="btn-configuration">
                      {categoriesData?.length !== 0 ? (
                        <Button type="primary" size="large" onClick={handleClickCategory}>
                          Edit Category
                        </Button>
                      ) : (
                        <Button type="primary" size="large" onClick={handleClickCategory} disabled={disableAddCategory}>
                          Add Category
                        </Button>
                      )}
                    </div>
                    <Table
                      className="table-weight"
                      dataSource={categoriesData}
                      columns={columnCategories}
                      onChange={() => onChange}
                      bordered
                    />
                  </>
                ) : (
                  <>
                    <Title level={3} style={{ fontWeight: 'bold' }}>
                      KPI CONFIGURATION
                    </Title>
                    {categoriesData && (
                      <Collapse className="Collapse-Category" onChange={onChangeCollapseKPI}>
                        {categoriesData.map((item) => (
                          <Panel header={`${item.name} (${(item.weight * 100).toFixed(2)}%)`} key={item.name}>
                            <div className="btn-configuration">
                              {kpisData?.length !== 0 ? (
                                <Button
                                  type="primary"
                                  size="large"
                                  onClick={() =>
                                    handleClickKPI(originalCategories.find((f) => f.name === item.name).id)
                                  }
                                >
                                  Edit KPI
                                </Button>
                              ) : (
                                <Button
                                  type="primary"
                                  size="large"
                                  onClick={() =>
                                    handleClickKPI(originalCategories.find((f) => f.name === item.name).id)
                                  }
                                >
                                  Add KPI
                                </Button>
                              )}
                            </div>
                            <Table
                              className="table-weight"
                              dataSource={getKpis(kpisData, item)}
                              columns={columnKpis}
                              pagination={false}
                              bordered
                            />
                          </Panel>
                        ))}
                      </Collapse>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Modal
            title="Category Configuration"
            visible={visibleModalCategory}
            width={'60%'}
            onOk={onSubmitModalCategory}
            onCancel={() => {
              setVisibleModalCategory(false);
              setHasError('');
              setDisableSaveCategory(true);
              setIsDividedCategory(false);
            }}
            okButtonProps={{ disabled: disableSaveCategory }}
            okText="Save"
          >
            {hasError && <Alert message="Error" description={hasError} type="error" showIcon />}
            {loading && <Spin indicator={antIcon} tip="Calculate..." />}
            <Title level={5}>Select your categories</Title>
            <Checkbox checked={isDividedCategory} onChange={(e) => handleChangeDividedCategory(e.target.checked)}>
              Divided equally
            </Checkbox>
            <Table dataSource={categoriesModel} columns={columnWeightCategories} />
          </Modal>

          <Modal
            title="KPI Configuration"
            visible={visibleModalKPI}
            width={'60%'}
            onOk={onSubmitModalKPI}
            onCancel={() => {
              setVisibleModalKPI(false);
              setHasError('');
              setDisableSaveKPI(true);
              setIsDividedKPI(false);
            }}
            okButtonProps={{ disabled: disableSaveKPI }}
            okText="Save"
          >
            {hasError && <Alert message="Error" description={hasError} type="error" showIcon />}
            {loading && <Spin indicator={antIcon} tip="Calculate..." />}
            <Title level={5}>Select your KPIs</Title>
            <Checkbox checked={isDividedKPI} onChange={(e) => handleChangeDividedKPI(e.target.checked)}>
              Divided equally
            </Checkbox>
            <Table dataSource={kpisModel} columns={columnWeightKPI} />
          </Modal>
        </div>
      </section>
    </div>
  );
};

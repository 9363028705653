import { SentimentNPSChartResponse } from './../types/metrics';
import { TeamResponse, TeamTypeEnum } from '../types/team';
import { groupBy, keys, max } from 'lodash';
import { monthOverMonth, npsMonthOverMonth } from './programData';
import moment from 'moment';
import { DATE_ID_FORMAT } from '../common/constants';
import {
  INpsData,
  npsAggregate,
  npsAggregateMeasure,
  npsDataResponse,
  npsTeamMeaSure,
  npsTeamScore,
} from '../types/nps';

export const npsAggregateMeasureByDate = (data: npsDataResponse[], dateId?: string): npsAggregateMeasure[] => {
  const dateGroups = groupBy(data, (item) => item.score.dateId);
  const dateIds = keys(dateGroups);
  dateId = dateId || max(dateIds);
  const current = dateId ? dateGroups[dateId] : null;

  const previousDateId = moment(dateId, DATE_ID_FORMAT).subtract(1, 'month').format(DATE_ID_FORMAT);
  const previous = dateGroups[previousDateId];

  return current?.map((item) => {
    const teamPrev = previous?.find((p) => p.score.teamId === item.score.teamId);
    return {
      dateId: item.score.dateId,
      teamId: item.score.teamId,
      team: item.score.team,
      nps: item.npsActual,
      previousNPS: teamPrev?.npsActual,
      npsMoM: monthOverMonth(item.npsActual, teamPrev?.npsActual),
    };
  });
};

export const npsAggregateByDate = (data: npsDataResponse[], dateId?: string): npsAggregate[] => {
  const dateGroups = groupBy(data, (item) => item.score.dateId);
  const dateIds = keys(dateGroups);
  dateId = dateId || max(dateIds);
  const current = dateId ? dateGroups[dateId] : null;
  const previousDateId = moment(dateId, DATE_ID_FORMAT).subtract(1, 'month').format(DATE_ID_FORMAT);
  const previous = dateGroups[previousDateId];

  return current?.map((item) => {
    const teamPrev = previous?.find((p) => p.score.teamId === item.score.teamId);
    return {
      previousNPS: teamPrev?.npsActual,
      dateId: item.score.dateId,
      teamId: item.score.teamId,
      team: item.score.team,
      nps: item.npsActual,
      promoterCount: item.score.promoterCount,
      passiveCount: item.score.passiveCount,
      detractorCount: item.score.detractorCount,
      npsPerformance: item.score.npsPerformance,
      npsScore: item.npsScore,
      previousScore: teamPrev?.npsScore,
      scoreMoM: monthOverMonth(item.npsScore, teamPrev?.npsScore),
      npsMoM: npsMonthOverMonth(item.score.npsPerformance, teamPrev?.score.npsPerformance),
    };
  });
};

export const toTeamMeasure = (items: npsAggregateMeasure[], teams: TeamResponse[]): npsTeamMeaSure[] => {
  return items?.map((item) => {
    const team = teams.find((t) => t.id === item.teamId);
    return { ...item, name: team.name, type: team.type };
  });
};

export const toTeamScore = (items: npsAggregate[], teams: TeamResponse[]): npsTeamScore[] => {
  return items?.map((item) => {
    const team = teams.find((t) => t.id === item.teamId);
    return { ...item, name: team.name, type: team.type };
  });
};

export const npsTransformData = (
  data: npsDataResponse[],
  teams: TeamResponse[],
  sentimentNpsData: SentimentNPSChartResponse,
  dateId?: string
): INpsData => {
  const aggregationScore = npsAggregateByDate(data, dateId);
  const aggregationMeasure = npsAggregateMeasureByDate(data, dateId);
  const dataGroups = groupBy(aggregationScore, (item) => teams.find((t) => t.id === item.teamId)?.type);
  const measureGroups = groupBy(aggregationMeasure, (item) => teams.find((t) => t.id === item.teamId)?.type);
  const engagementNps = toTeamMeasure(measureGroups[TeamTypeEnum.Engagement], teams)?.filter(
    (item) => item.nps != null
  );
  const teamNps = toTeamMeasure(measureGroups[TeamTypeEnum.Team], teams)?.filter((item) => item.nps != null);

  return {
    programScore: { ...dataGroups[TeamTypeEnum.Program]?.[0], name: 'Program', type: 'Program' },
    departmentScores: toTeamScore(dataGroups[TeamTypeEnum.Department], teams),
    engagmentActuals: engagementNps,
    teamActuals: teamNps,
    sentimentNpsData: sentimentNpsData,
  };
};

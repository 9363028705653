import { Button, Row, Col, Modal } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deleteNote } from '../apis/noteClient';
import { CreateNote } from './CreateNote';
import { NoteMode, NoteModel, NoteResponse } from '../types/note';
import moment from 'moment';
import { UserContext } from '../contexts/UserContext';
import { StyledNoteTitle } from './Note.styled';
import { ROLE } from '../common/constants';
import { AuthContext } from '../contexts/AuthContext';

interface IProps {
  note: NoteResponse;
  title?: string;
  onChanged: () => void;
  isReplyable?: boolean;
  editable?: boolean;
}
const Note = ({ note, onChanged, title, isReplyable = false, editable = false }: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [mode, setMode] = useState<NoteMode>('create');
  const [openEditBox, setOpenEditBox] = useState(false);
  const [isEditable, setIsEditable] = useState(editable);
  const [isDeleteable, setIsDeleteable] = useState(editable);
  const { users } = useContext(UserContext);
  const name = users?.find((item) => item.id === note.creatorUserId)?.fullName;
  const role = users?.find((item) => item.id === note.creatorUserId)?.roles;

  const {
    user: { id, isAdmin },
  } = useContext(AuthContext);
  const currentUserId = parseInt(id.toString());
  const isC99User = currentUserId !== parseInt('2');
  useEffect(() => {
    if (currentUserId !== note.creatorUserId) setIsEditable(false);
  }, [isEditable]);

  const deleteNoteById = async (id: number) => {
    await deleteNote(id);
    onChanged();
    setModalVisible(false);
  };

  const createdTime = moment(note.creationTime).format('DD-MM-YYYY');
  const isCAPGUser = role?.includes(ROLE.CAPG);

  return openEditBox && mode === 'edit' ? (
    <CreateNote isOpen={openEditBox} setVisible={setOpenEditBox} note={note} onChanged={onChanged} mode={mode} />
  ) : (
    <Row key={note.id}>
      <Col span={24}>
        <StyledNoteTitle>{title}</StyledNoteTitle>
        {isCAPGUser ? (
          <>
            <Row className="msg-container" style={{ backgroundColor: '#feffda' }}>
              <div style={{ whiteSpace: 'pre-line', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {note.description}
              </div>
            </Row>
            {mode === 'reply' && isReplyable && (
              <CreateNote
                isOpen={openEditBox}
                setVisible={setOpenEditBox}
                note={{ id: 0, teamId: note.teamId, parentId: note.id, dateId: note.dateId } as NoteModel}
                onChanged={onChanged}
                mode={mode}
              />
            )}
          </>
        ) : (
          <>
            <Row className="msg-container">
              <div style={{ whiteSpace: 'pre-line', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {note.description}
              </div>
            </Row>
            {mode === 'reply' && isReplyable && (
              <CreateNote
                isOpen={openEditBox}
                setVisible={setOpenEditBox}
                note={{ id: 0, teamId: note.teamId, parentId: note.id, dateId: note.dateId } as NoteModel}
                onChanged={onChanged}
                mode={mode}
              />
            )}
          </>
        )}
        <Row className="filter-row">
          {isReplyable && isC99User && (
            <Col span={2}>
              <Button
                type="ghost"
                onClick={() => {
                  setMode('reply');
                  setOpenEditBox(true);
                }}
                style={{ display: 'contents', color: '#1890ff' }}
              >
                <u>Reply</u>
              </Button>
            </Col>
          )}
          <Col style={{ textAlign: 'right', marginRight: '10px' }} flex="auto">
            Created By {name} ({createdTime})
          </Col>
          {(isAdmin || isEditable) && (
            <Col className="btn-group-container" offset={1} span={2} style={{ textAlign: 'right' }}>
              <Button
                type="ghost"
                onClick={() => {
                  setMode('edit');
                  setOpenEditBox(true);
                }}
                icon={<EditOutlined />}
                style={{ display: 'contents' }}
              />
              <Button
                type="ghost"
                onClick={() => {
                  setModalVisible(true);
                }}
                icon={<DeleteOutlined />}
                style={{ display: 'contents' }}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Modal
        title="Confirm Delete"
        centered
        visible={modalVisible}
        onOk={() => deleteNoteById(note.id)}
        onCancel={() => setModalVisible(false)}
        destroyOnClose={true}
      >
        <p>Are you sure you want to delete this note?</p>
      </Modal>
    </Row>
  );
};

export default Note;

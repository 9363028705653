import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router';
import { Breadcrumb, Button, notification, Popconfirm } from 'antd';

import { ProjectScore } from '../charts/ProjectScore';
import { ThemeScore } from '../charts/ThemeMetrics';
import { Card } from '../components/Card';
import { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import { ProjectDataContextProvider } from '../contexts/ProjectDataContext';
import { ProjectTrending } from '../charts/ProjectTrending';
import { ScoreEngagementTrending } from '../charts/ScoreEngagementTrending';
import { Col, DatePicker, Row } from 'antd';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../common/constants';
import fetchTeamData from '../apis/fetchTeamData';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import { EngagementDataContextProvider } from '../contexts/EngagementDataContext';
import { EngagementTeamScore } from '../charts/EnagementTeamScores';
import { EngagementMonthlyData } from '../charts/EngagementMonthlyData';
import { Helmet } from 'react-helmet';
import { BellFilled, BellOutlined, CheckOutlined } from '@ant-design/icons';
import { useFetch } from '../hooks/useFetch';
import { getNotiEngagement, sendNotiEngagement } from '../apis/teamClient';
import { AuthContext } from '../contexts/AuthContext';
import { ProjectPSFlowTrending } from '../charts/ProjectPSFlowTrending';

interface RouteParams {
  engagementId: string;
}
const lastMonth = moment().subtract(1, 'month');

export const EngagementDashboard = () => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const { engagementId } = useParams<RouteParams>();
  const { teams } = useContext(TeamContext);
  const history = useHistory();
  const team = teams.find((t) => `${t.id}` === engagementId);
  const month = moment(params.get('dateId') || lastMonth, DATE_ID_FORMAT).toDate();
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [confirmNotify, setConfirmNotify] = useState<boolean>(false);
  const [loadButton, setLoadButton] = useState<boolean>(false);
  const [isLoadingSendMail, setIsLoadingSendMail] = useState<boolean>(false);
  const [DateIdInMonth, setDateIdInMonth] = useState<number>(parseInt(moment(month).format('YYYYMM')));

  useEffect(() => {
    setDateIdInMonth(parseInt(moment(month).format('YYYYMM')));
  }, [month]);
  const { data: notiEngagement } = useFetch(() => {
    return getNotiEngagement(DateIdInMonth);
  }, [DateIdInMonth]);
  const downloadFile = async () => {
    try {
      setIsLoadingDownload(true);
      const result = await fetchTeamData(team.id);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      setIsLoadingDownload(false);
    }
  };
  const {
    user: { isAdmin },
  } = useContext(AuthContext);

  const dateId = moment(month).format(DATE_ID_FORMAT);
  const notiTeam = notiEngagement?.find((item) => item.teamId === parseInt(engagementId));
  useEffect(() => {
    setLoadButton(notiTeam?.notified);
  }, [notiTeam]);

  const openNotification = (statusCode: number, message: string) => {
    if (statusCode === 400) {
      notification.warning({
        message,
        duration: 3,
      });

      return;
    }

    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 2,
    });
  };

  const handleNotify = () => {
    if (notiTeam?.notified) {
      setConfirmNotify(true);
    } else {
      confirmSendMail();
    }
  };

  const confirmSendMail = async () => {
    try {
      setIsLoadingSendMail(true);

      if (confirmNotify) {
        setConfirmNotify(false);
      }

      await sendNotiEngagement({ dateId: DateIdInMonth, teamId: parseInt(engagementId) });
      notification.success({
        message: `SendMail successfully`,
        duration: 2,
      });

      setLoadButton(true);

      if (notiTeam) {
        notiTeam.notified = true;
      }
    } catch (error) {
      openNotification(error.status, error.data?.error?.message);
    } finally {
      setIsLoadingSendMail(false);
    }
  };
  return (
    <ProjectDataContextProvider dateId={dateId} projectId={engagementId}>
      <div className="breadCumb_TeamDetail">
        <Breadcrumb>
          <Breadcrumb.Item>
            <span style={{ color: '#1890ff' }}>{teams.find((item) => item.id === team.parentId)?.name}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span style={{ color: '#00000073' }}>{team.name} Overview</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Helmet>
        <title>{team.name} Overview</title>
      </Helmet>
      <EngagementDataContextProvider dateId={dateId} id={engagementId}>
        <div className="page-home page-container">
          <section className="section-container section-padding">
            <div className="section-title-container">
              <span className="section-title">{team.name} Overview</span>
              <DatePicker
                value={moment(month)}
                disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
                onChange={(e) => {
                  e && history.push(`${pathname}?dateId=${e.format(DATE_ID_FORMAT)}`);
                }}
                format={MONTH_PICKER_FORMAT}
                picker="month"
                allowClear={false}
              />
              <Button
                size="large"
                type="primary"
                className="download-button"
                onClick={downloadFile}
                loading={isLoadingDownload}
              >
                Download Report
              </Button>
              {isAdmin && (
                <Popconfirm
                  placement="top"
                  title="Do you want to resend the notification?"
                  visible={confirmNotify}
                  onConfirm={confirmSendMail}
                  okText="Yes"
                  cancelText="No"
                  onCancel={() => setConfirmNotify(false)}
                >
                  <Button size="large" className="notify-button" onClick={handleNotify} loading={isLoadingSendMail}>
                    {loadButton ? (
                      <>
                        <CheckOutlined />
                        <BellFilled />
                      </>
                    ) : (
                      <BellOutlined />
                    )}{' '}
                    Notify
                  </Button>
                </Popconfirm>
              )}
            </div>
            <Row gutter={24}>
              <Col className="chart-Program" xxl={6} xl={6} lg={12} md={24}>
                <Card title="Engagement score">
                  <ProjectScore />
                </Card>
              </Col>
              <Col className="chart-Program" xxl={7} xl={8} lg={12} md={24}>
                <Card title="Score by Category">
                  <ThemeScore />
                </Card>
              </Col>
              <Col className="chart-Program" xxl={11} xl={10} lg={24}>
                <Card title="Top Score by Teams">
                  <EngagementTeamScore />
                </Card>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col className="chart-Program" xl={12} lg={24}>
                <Card title="Overall Score Trends">
                  <ScoreEngagementTrending />
                </Card>
              </Col>
              <Col className="chart-Program" xl={12} lg={24}>
                <Card title="Score Trends by Category">
                  <ProjectTrending />
                </Card>
              </Col>
            </Row>
            {/* <Row gutter={24}>
              <Col className="chart-Program" xl={12} lg={24}>
                <Card title="Coding Performance Score Flow Trends">
                  <ProjectPSFlowTrending type={'Score'} />
                </Card>
              </Col>
              <Col className="chart-Program" xl={12} lg={24}>
                <Card title="Coding Performance Actual Flow Trends">
                  <ProjectPSFlowTrending type={'Actual'} />
                </Card>
              </Col>
            </Row> */}
          </section>

          <section className="section-container">
            <div className="section-title-container">
              <span className="section-title">Monthly Score</span>
            </div>
            <EngagementMonthlyData />
          </section>
        </div>
      </EngagementDataContextProvider>
    </ProjectDataContextProvider>
  );
};

import { fetchAsync } from '../utils/fetch';

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
}

export const postTeamsTemplate = async (file: File) => {
  const data = new FormData();
  data.append('file', file);
  const result = fetchAsync('/api/team/import', {
    method: 'POST',
    body: data,
    type: 'blob',
  });
  return result;
};

import { ScoreResponse, IProgramData, AggregatedScore, TeamScore, ScoreCardData } from '../types/metrics';
import { TeamResponse, TeamTypeEnum } from '../types/team';
import moment from 'moment';
import { groupBy, keys, max, round } from 'lodash';
import { DATE_ID_FORMAT } from '../common/constants';

export const monthOverMonth = (curr: number, prev: number) => (prev != null ? round((curr / prev - 1) * 100, 1) : null);
export const npsMonthOverMonth = (curr: number, prev: number) => (prev != null ? round(curr - prev, 1) : null);

export const aggregateByDate = (data: ScoreCardData[], dateId?: string): AggregatedScore[] => {
  const dateGroups = groupBy(data, (item) => item.dateId);
  const dateIds = keys(dateGroups);
  dateId = dateId || max(dateIds);
  const current = dateId ? dateGroups[dateId] : null;
  const previousDateId = moment(dateId, DATE_ID_FORMAT).subtract(1, 'month').format(DATE_ID_FORMAT);
  const previous = dateGroups[previousDateId];

  return current?.map((item) => {
    const teamPrev = previous?.find((p) => p.teamId === item.teamId);
    return {
      ...item,
      previousScore: teamPrev?.score,
      scoreMoM: monthOverMonth(item.score, teamPrev?.score),
      npsMoM: npsMonthOverMonth(item.npsPerformance, teamPrev?.npsPerformance),
    };
  });
};

export const toTeamScore = (items: AggregatedScore[], teams: TeamResponse[]): TeamScore[] => {
  return items?.map((item) => {
    const team = teams.find((t) => t.id === item.teamId);
    return { ...item, name: team.name, type: team.type, score: Number(item.score?.toFixed(2)) };
  });
};

export const transform = (data: ScoreCardData[], teams: TeamResponse[], dateId?: string): IProgramData => {
  const aggregation = aggregateByDate(data, dateId);
  const dataGroups = groupBy(aggregation, (item) => teams.find((t) => t.id === item.teamId)?.type);

  return {
    programScore: { ...dataGroups[TeamTypeEnum.Program]?.[0], name: 'Program', type: 'Program' },
    departmentScores: toTeamScore(dataGroups[TeamTypeEnum.Department], teams),
    engagementScores: toTeamScore(dataGroups[TeamTypeEnum.Engagement], teams),
    teamScores: toTeamScore(dataGroups[TeamTypeEnum.Team], teams),
  };
};

export const roundUpScore = (list, ...ignoreKeys) => {
  list?.forEach(function (r) {
    let rValues = Object.entries(r);
    rValues.forEach(function (e) {
      // e[0] is the key and e[1] is the value
      if (!ignoreKeys.includes(e[0])) {
        let n = Number(e[1]);
        if (!isNaN(n)) {
          r[e[0]] = n.toFixed(2);
        } else r[e[0]] = '';
      }
    });
  });
  return list;
};

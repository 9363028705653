import { AggregatedScore, IProgramTrendingData } from '../types/metrics';
import { TeamResponse, TeamTypeEnum } from '../types/team';
import { groupBy } from 'lodash';
import { toTeamScore } from './programData';

export const transformData = (data: AggregatedScore[], teams: TeamResponse[]): IProgramTrendingData => {
  const dataGroups = groupBy(data, (item) => teams.find((t) => t.id === item.teamId)?.type);
  return {
    programDatas: toTeamScore(dataGroups[TeamTypeEnum.Program], teams),
    departmentDatas: toTeamScore(dataGroups[TeamTypeEnum.Department], teams),
    engagementDatas: toTeamScore(dataGroups[TeamTypeEnum.Engagement], teams),
    teamDatas: toTeamScore(dataGroups[TeamTypeEnum.Team], teams),
  };
};

import {
  CommentActualResponse,
  CommentModel,
  DeleteComment,
  MeasureNameList,
  MissingActualRequest,
  MissingActualResponse,
} from '../types/healthy';
import { fetchAsync } from '../utils/fetch';
import { serializeQuery } from '../utils/stringUtils';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(serializeQuery(query));
  return urlParams.toString();
};

export const fetchMeasureNameList = (): Promise<MeasureNameList[]> => fetchAsync(`/api/healthy/measure-names`);

export const fetchMissingActual = (query: MissingActualRequest): Promise<MissingActualResponse[]> =>
  fetchAsync(`/api/healthy/missing-actual?${toUrlSearchParams(query)}`);

export const CreateCommentActual = (Comment: CommentModel): Promise<CommentActualResponse> =>
  fetchAsync(`/api/Comment`, {
    method: 'POST',
    body: Comment,
  });

export const deleteComment = (Comment: DeleteComment): Promise<void> =>
  fetchAsync(`/api/Comment/Delete`, {
    method: 'DELETE',
    body: Comment,
  });

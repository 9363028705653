/* eslint-disable react/display-name */
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  TablePaginationConfig,
} from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { createTeam, deleteTeam, updateTeam } from '../apis/teamClient';
import { EditableColumn, EditableTable } from '../components/EditableTable';
import { TeamSelect } from '../components/TeamSelect';
import { TeamContext } from '../contexts/TeamContext';
import { TeamModel, teamOptions, TeamResponse, TeamType } from '../types/team';
import { deleteItem, insertItem, updateItem } from '../utils/tableUtils';
import { SearchOutlined } from '@ant-design/icons';
import './ManageTeams.scss';
import { PageTitle, ToolbarContainer } from '../layouts/AdminLayout.styled';
import Title from 'antd/lib/typography/Title';
import { DUPLICATE_TEAM_MESSAGE, GENERIC_MESSAGE } from '../common/constants';
import { USER_PAGE_NUMBER } from '../common/constants';
import { Helmet } from 'react-helmet';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const openNotification = (statusCode: number, message: string) => {
  const ERROR_MESSAGE = message === 'DuplicateTeamName' ? DUPLICATE_TEAM_MESSAGE : GENERIC_MESSAGE;
  notification.error({
    message: `Error: ${statusCode}`,
    description: `${ERROR_MESSAGE}`,
    duration: 2,
  });
};
//=====================================================================

const { Search } = Input;
const getParentType = (type: TeamType): TeamType => {
  switch (type) {
    case 'Team':
      return 'Engagement';
    case 'Engagement':
      return 'Department';

    case 'Department':
      return 'Program';
    default:
      return null;
  }
};

export const ManageTeams = () => {
  const { teams: data, setTeams: setData } = useContext(TeamContext);
  const [addTeamDialog, showAddTeamDialog] = useState(false);
  const [form] = Form.useForm<TeamModel>();
  const [activeType, setActiveType] = useState<TeamType>('Team');
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchEl = useRef(null);
  const [hasError, setHasError] = useState<Response>();
  const [page, setPage] = useState(1);

  const getColumnSearchProps = (dataIndex) => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 5 }}>
          <Search
            ref={searchEl}
            allowClear
            onChange={(e: any) => {
              if (e._reactName === 'onChange') {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              } else {
                clearFilters();
              }
            }}
            placeholder="Filter By Name"
            onSearch={() => handleSearch(selectedKeys, confirm, dataIndex)}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            value={selectedKeys[0]}
            style={{ width: 200 }}
          />
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchEl.current.select(), 100);
      }
    },
    render: (text) => {
      return text;
    },
  });

  const columns: EditableColumn<TeamResponse>[] = [
    {
      title: '#',
      width: '10px',
      render: (value, record, index) => (page - 1) * USER_PAGE_NUMBER + index + 1,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      editable: true,
      rules: [{ required: true, message: 'This field is required' }],
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      editable: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      inputType: 'select',
      options: teamOptions,
    },
    {
      title: 'Parent',
      dataIndex: 'parentId',
      editable: true,
      inputType: 'select',
    },
  ];
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    if (!data) {
      return;
    }

    const newColumns = [...columns];
    const index = newColumns.findIndex((item) => item.dataIndex === 'parentId');
    const parentCol = newColumns[index];

    parentCol.options = data
      .filter((item) => item.type === getParentType(activeType))
      .map((item) => ({ value: item.id, label: item.name }));

    setTableColumns(newColumns);
  }, [activeType, data, page]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };

  const onSave = async (id: number, { code, name, parentId, type }: TeamResponse) => {
    try {
      await updateTeam(id, { code, name, parentId, type });
      const newData = updateItem({ id, code, name, type, parentId }, data);
      setData(newData);
    } catch (error) {}
  };

  const onDelete = async (id: number) => {
    try {
      await deleteTeam(id);
      const newData = deleteItem(id, data);
      setData(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    try {
      const { code, name, type, parentId } = form.getFieldsValue();
      const newTeam = await createTeam({
        code,
        name,
        parentId,
        type,
      });
      form.resetFields();
      const newData = insertItem(newTeam, data);
      setData(newData);
      showAddTeamDialog(false);
    } catch (error) {
      console.log(error);
      setHasError(error);
      openNotification(error.status, error.message);
    }
  };

  const tableData = data?.filter((item) => item.type === activeType);
  const onChangeTeamType = ({ target: { value } }: RadioChangeEvent) => {
    setActiveType(value);
  };

  const onValueChanges = () => {
    setHasError(undefined);
  };

  document.title = 'Vendor Score Card';
  return (
    <Card className="page-manage-teams">
      <Helmet>
        <title>Manage Teams</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>Manage Teams</Title>
      </PageTitle>
      <ToolbarContainer>
        <Row>
          <Col span={12}>
            <div>
              <Button type="primary" onClick={() => showAddTeamDialog(true)}>
                Add New Team
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div className="team-type-button-group">
              <Radio.Group
                options={teamOptions}
                onChange={onChangeTeamType}
                value={activeType}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          </Col>
        </Row>
      </ToolbarContainer>
      <EditableTable data={tableData} columns={tableColumns} onSave={onSave} onDelete={onDelete} onChange={onChange} />
      <Modal title="Add New Team" visible={addTeamDialog} onOk={onSubmit} onCancel={() => showAddTeamDialog(false)}>
        <Form
          style={{ marginTop: '1rem' }}
          {...layout}
          form={form}
          name="basic"
          initialValues={{ type: 'Team' }}
          onValuesChange={onValueChanges}
        >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Code" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={[{ required: true, message: 'This field is required!' }]}>
            <Select
              options={teamOptions}
              onChange={() => form.setFieldsValue({ parentId: null })}
              suffixIcon={<SearchOutlined />}
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) => {
              return prev.type !== curr.type || prev.parentId !== curr.parentId;
            }}
          >
            {() => (
              <Form.Item label="Parent" name="parentId">
                <TeamSelect showSearch type={getParentType(form.getFieldValue('type'))} />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

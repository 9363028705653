import { Pie } from '@ant-design/charts';
import { Empty } from 'antd';
import { useContext } from 'react';
import {
  ABOVE_TARGET,
  BELOW_TARGET,
  COLOR_ABOVE_TARGET,
  COLOR_BELOW_TARGET,
  COLOR_ON_TARGET,
} from '../common/constants';

import { ProgramDataContext } from '../contexts/ProgramDataContext';

const BELOW_TARGET_LABEL = 'Below Target';
const ON_TARGET_LABEL = 'On Target';
const ABOVE_TARGET_LABEL = 'Above Target';

interface IProps {
  type: string;
}

export const ProgramPerformance = ({ type }: IProps) => {
  const { engagementScores, teamScores } = useContext(ProgramDataContext);

  if (!engagementScores?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  let scoresNotNull = teamScores.filter((item) => item.score != null);
  if (type === 'engagement') {
    scoresNotNull = engagementScores.filter((item) => item.score != null);
  }
  const scoresSummary = {
    belowTarget: scoresNotNull.filter(({ score }) => score >= 0 && score <= BELOW_TARGET).length,
    onTarget: scoresNotNull.filter(({ score }) => score > BELOW_TARGET && score <= ABOVE_TARGET).length,
    aboveTarget: scoresNotNull.filter(({ score }) => score > ABOVE_TARGET).length,
  };

  const data = [
    {
      label: BELOW_TARGET_LABEL,
      value: scoresSummary.belowTarget,
    },
    {
      label: ON_TARGET_LABEL,
      value: scoresSummary.onTarget,
    },
    {
      label: ABOVE_TARGET_LABEL,
      value: scoresSummary.aboveTarget,
    },
  ];

  return (
    <Pie
      data={data}
      height={300}
      angleField="value"
      colorField="label"
      color={({ label }) => {
        switch (label) {
          case BELOW_TARGET_LABEL:
            return COLOR_BELOW_TARGET;
          case ON_TARGET_LABEL:
            return COLOR_ON_TARGET;
          case ABOVE_TARGET_LABEL:
            return COLOR_ABOVE_TARGET;
          default:
            return '';
        }
      }}
      legend={{
        position: 'top',
      }}
    />
  );
};

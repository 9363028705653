import { Form, Input, Button, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { changePassword } from '../apis/userClient';
import { ChangePasswordModel } from '../types/user';

interface IProps {
  id: number | string;
  isOpenDialog: boolean;
  setVisible: (param: boolean) => void;
}

export const ResetPassword = ({ id, isOpenDialog, setVisible }: IProps) => {
  const [error, setError] = useState(null);
  const openNotification = () => {
    notification.success({
      message: `Change password successfully`,
      duration: 2,
    });
  };
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  };
  const onFinish = async (values: ChangePasswordModel) => {
    try {
      await changePassword(id, values);
      openNotification();
      setVisible(false);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <>
      {isOpenDialog && (
        <Form
          style={{ marginTop: '1rem' }}
          {...layout}
          name="Reset Password"
          colon={false}
          onFinish={onFinish}
          id="myForm"
        >
          <Modal
            title="Reset password"
            visible={isOpenDialog}
            onCancel={() => setVisible(false)}
            style={{ textAlign: 'center' }}
            footer={[
              <div key="xxx" style={{ textAlign: 'center' }}>
                <Button
                  key="cancel"
                  style={{ backgroundColor: 'Gainsboro', width: '100px' }}
                  onClick={() => setVisible(false)}
                >
                  Cancel
                </Button>
                <Button form="myForm" type="primary" htmlType="submit" style={{ width: '100px' }}>
                  Ok
                </Button>
              </div>,
            ]}
          >
            <Form.Item
              label="New password"
              name="newpassword"
              rules={[
                { required: true, message: 'Please input your new password!' },
                { min: 6, message: 'The password must be minimum 6 characters.' },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="verifypassword"
              rules={[
                { required: true, message: 'Please input your verify password!' },
                { min: 6, message: 'The verify password must be minimum 6 characters.' },

                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newpassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
              dependencies={['newpassword']}
            >
              <Input.Password />
            </Form.Item>
          </Modal>
        </Form>
      )}
    </>
  );
};

export default ResetPassword;

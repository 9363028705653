import { createContext, useContext } from 'react';
import { useFetch } from '../hooks/useFetch';
import { Spin } from 'antd';
import { fetchScores } from '../apis/scoreClient';
import { TeamContext } from './TeamContext';
import { transformData } from '../utils/programTrendingData';
import moment from 'moment';
import { DATE_ID_FORMAT } from '../common/constants';
import { IProgramTrendingData } from '../types/metrics';
export const ProgramTrendingDataContext = createContext<IProgramTrendingData>(null);

interface IProps {
  children: React.ReactNode;
}

export const ProgramTrendingDataContextProvider = ({ children }: IProps) => {
  const { teams } = useContext(TeamContext);
  const endDateId = moment().format(DATE_ID_FORMAT);
  const startDateId = moment().subtract(12, 'months').format(DATE_ID_FORMAT);
  const { data, loading, error } = useFetch(
    () =>
      fetchScores({
        startDateId,
        endDateId,
      }),
    []
  );

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }
  return (
    <Spin spinning={loading}>
      <ProgramTrendingDataContext.Provider value={transformData(data, teams)}>
        {children}
      </ProgramTrendingDataContext.Provider>
    </Spin>
  );
};

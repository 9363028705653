import { Line } from '@ant-design/charts';
import { minBy, orderBy, startCase } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';

import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { AggregatedScore } from '../types/metrics';
import { DATE_ID_FORMAT } from '../common/constants';
import { Empty } from 'antd';
import { targetAnnotation } from '../utils/chartUtils';

const createSeriesData = (props: (keyof AggregatedScore)[], data: AggregatedScore) => {
  return props.map((p) => ({
    value: Number(Number(data[p]).toFixed(2)),
    category: startCase(p),
    date: moment(`${data.dateId}`, DATE_ID_FORMAT).format('MMM YY'),
  }));
};

export const TeamScoreTrending = () => {
  const { scores } = useContext(ProjectDataContext);
  const endDateId = moment().format(DATE_ID_FORMAT);
  const startDateId = moment().subtract(12, 'months').format(DATE_ID_FORMAT);
  const scoresWithin12Months = scores.filter((item) => +endDateId >= item.dateId && item.dateId >= +startDateId);

  const data = orderBy(scoresWithin12Months, (item) => item.dateId)
    .flatMap((item) => createSeriesData(['score'], item))
    .filter((item) => item.value != null);

  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Line
      data={data}
      height={300}
      yField="value"
      xField="date"
      seriesField="category"
      point={{
        shape: 'diamond',
        size: 5,
      }}
      yAxis={{
        max: 1.05,
        tickInterval: 0.05,
        min: Math.min(minBy(data, (item) => item.value)?.value, 0.5),
      }}
      label={{}}
      annotations={targetAnnotation(scores.length)}
    />
  );
};

import { Button, Form, Input } from 'antd';
import { createNote, updateNote } from '../apis/noteClient';
import { NoteMode, NoteModel } from '../types/note';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 14 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
};
interface IProps {
  isOpen: boolean;
  setVisible: (param: boolean) => void;
  note: NoteModel;
  onChanged: () => void;
  mode?: NoteMode;
}

export const CreateNote = ({ isOpen, setVisible, note, onChanged, mode }: IProps) => {
  const doSaveNote = async (data: NoteModel) => {
    if (note.id > 0) {
      await updateNote(note.id, { ...note, description: data.description });
    } else {
      await createNote({ ...note, description: data.description });
    }
    setVisible(false);
    onChanged();
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      {isOpen ? (
        <Form
          {...layout}
          className="form-group"
          name="nest-messages"
          validateMessages={validateMessages}
          onFinish={doSaveNote}
        >
          <Form.Item
            wrapperCol={{ ...layout.labelCol }}
            name={'description'}
            label="Notes"
            rules={[{ required: true }]}
          >
            <Input.TextArea allowClear rows={4} maxLength={3000} defaultValue={note.description} />
          </Form.Item>
          <Form.Item
            className="form-group_btn-group"
            wrapperCol={{ ...layout.wrapperCol }}
            style={{ textAlign: 'left' }}
          >
            <Button className="form-btn" type="primary" htmlType="submit">
              {mode === 'edit' ? 'Save' : 'Create Note'}
            </Button>
            <Button onClick={handleCancel} className="form-btn" type="primary">
              Cancel
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </>
  );
};

import { fetchAsync } from '../utils/fetch';

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
}

export const postTargetTemplate = async (file: File, dateId) => {
  const data = new FormData();
  data.append('file', file);
  data.append('dateId', dateId);
  const result = fetchAsync('/api/target/import', {
    method: 'POST',
    body: data,
    type: 'blob',
  });
  return result;
};

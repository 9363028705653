import { fetchAsync } from '../utils/fetch';
import { serializeQuery } from '../utils/stringUtils';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(serializeQuery(query));
  return urlParams.toString();
};

export const ExportHeatMapEngagement = async (DateId: number, TeamType: string) => {
  const result = await fetchAsync(`/api/excel/ExportHeatMap?DateId=${DateId}&TeamType=${TeamType}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

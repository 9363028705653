import { Typography } from 'antd';
import { Upload, Button, Table, Alert } from 'antd';
import { useState } from 'react';
import { postTeamsTemplate } from '../apis/postTeamsTemplate';
import { PageTitle } from '../layouts/AdminLayout.styled';
import { ImportBody, ImportDescription, ImportHeader, ImportLayout } from '../layouts/ImportLayout.styled';
import { Helmet } from 'react-helmet';

const { Title } = Typography;

const columns = [
  {
    title: 'Position',
    dataIndex: 'members',
  },
  {
    title: 'Message',
    dataIndex: 'message',
  },
];

export const ImportTeams = () => {
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [file, setFile] = useState();
  const [isDisableUpload, setIsDisableUpload] = useState<boolean>(true);
  const [isDisableBrowse] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [listErrors, setListErrors] = useState();

  const setSelectedFile = (selectedFile) => {
    setFile(selectedFile);
    setListErrors(null);
    setIsDisableUpload(false);
    return false;
  };

  const handleUpload = async () => {
    try {
      setIsLoadingUpload(true);
      setIsOpen(false);
      setIsDisableUpload(true);
      setListErrors(null);
      const result = await postTeamsTemplate(file);
      setIsOpen(true);
    } catch (error) {
      if (error.data.error.validationErrors !== null) {
        let err = error.data.error.validationErrors.map((e) => e);
        setListErrors(err);
      } else {
        alert('Failed! Please correct the file.');
      }
    } finally {
      setIsLoadingUpload(false);
    }
  };

  return (
    <ImportLayout>
      <Helmet>
        <title>Import Teams</title>
      </Helmet>
      <ImportHeader>
        <PageTitle>
          <Title level={3}>Import Teams</Title>
        </PageTitle>
      </ImportHeader>
      <ImportBody>
        <Title level={4}>Bulk upload Teams</Title>
        <Upload beforeUpload={setSelectedFile} accept=".xlsx" maxCount={1}>
          <Button size="large" type="primary" style={{ marginRight: '20px' }} disabled={isDisableBrowse}>
            Browse
          </Button>
        </Upload>
        <Button
          size="large"
          type="primary"
          onClick={handleUpload}
          disabled={isDisableUpload}
          loading={isLoadingUpload}
          style={{ marginTop: '20px' }}
        >
          {isLoadingUpload ? 'Uploading' : 'Start Upload'}
        </Button>
        {isOpen && <Alert closable message="Successfully" type="success" showIcon />}
        {listErrors && (
          <Table
            className="table-error"
            scroll={{ y: 300 }}
            columns={columns}
            dataSource={listErrors}
            pagination={false}
          />
        )}
      </ImportBody>
    </ImportLayout>
  );
};

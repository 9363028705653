import { Select, SelectProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { TeamContext } from '../contexts/TeamContext';
import { SelectOptions, TeamType } from '../types/team';
import { groupBy, keys } from 'lodash';

interface IProps extends SelectProps<any> {
  type: TeamType | TeamType[];
  parentId?: number;
}

const filterByName = (value: string, options: SelectOptions<number>) => {
  return options.label.toLowerCase().indexOf(value.toLowerCase()) >= 0;
};

export const TeamSelect = ({ type: types, parentId, ...restProps }: IProps) => {
  const { teams } = useContext(TeamContext);
  const teamOptions = teams
    .filter((item) => types === item.type || types.includes(item.type))
    .map((item) => ({ value: item.id, label: item.name, type: item.type }));

  let options;
  if (Array.isArray(types) && types.length > 1) {
    const groups = groupBy(teamOptions, (item) => item.type);
    options = keys(groups).map((key) => ({ label: key, options: groups[key] }));
  } else {
    options = teamOptions;
  }

  return <Select options={options} filterOption={filterByName} {...restProps} suffixIcon={<SearchOutlined />} />;
};

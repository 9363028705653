import { Empty, Table } from 'antd';
import { CategoryWeightResponse, KPIWeightResponse } from '../types/metrics';
import { ColumnsType } from 'antd/lib/table';
import './MonthlyData.scss';

const DATE_COL_WIDTH = 75;
const LABEL_COL_WIDTH = 100;

interface IProps {
  kpiWeight: KPIWeightResponse[];
  categoryWeight: CategoryWeightResponse[];
  dateId: number;
}

const getRowSpans = (arr, key) => {
  let sameValueLength = 0;
  const rowSpans = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    if (i === 0) {
      rowSpans[i] = sameValueLength + 1;
      continue;
    }
    if (arr[i][key] === arr[i - 1][key]) {
      rowSpans[i] = 0;
      sameValueLength++;
    } else {
      rowSpans[i] = sameValueLength + 1;
      sameValueLength = 0;
    }
  }
  return rowSpans;
};

const createMonthlyColumns = (rowSpans: any[], categoryWeight: CategoryWeightResponse[]) => {
  const dateCols: ColumnsType = [
    {
      title: 'Category',
      dataIndex: 'categoryName',
      width: '15%',
      key: 'categoryName',
      fixed: 'left',
      render: (value, record: KPIWeightResponse, index) => {
        const obj = {
          children:
            record.categoryName +
            ' (' +
            categoryWeight?.find((item) => item.name === record.categoryName)?.weight * 100 +
            '%)',
          props: { rowSpan: rowSpans[index] },
        };
        return obj;
      },
      onCell: (record: KPIWeightResponse) => ({
        style: {
          backgroundColor: record?.color,
        },
      }),
    },
    {
      dataIndex: 'name',
      title: 'KPI',
      fixed: 'left',
      width: '30%',
      render: (value, record: KPIWeightResponse, index) => record.name,
      onCell: (record: KPIWeightResponse) => ({
        style: {
          backgroundColor: record?.color,
        },
      }),
    },
    {
      title: 'KPI Weight',
      dataIndex: 'weight',
      width: '5%',
      align: 'center',
      render: (value, record: KPIWeightResponse, index) => record.weight,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '50%',
      render: (value, record: KPIWeightResponse, index) => record.description,
    },
  ];
  return dateCols;
};

export const TeamWeightMonthlyTable = ({ kpiWeight, categoryWeight, dateId }: IProps) => {
  if (kpiWeight.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  kpiWeight = kpiWeight.sort((a, b) => (a.categoryName.toLowerCase() > b.categoryName.toLowerCase() ? 1 : -1));
  const rowSpans = getRowSpans(kpiWeight, 'categoryName');
  const cols = createMonthlyColumns(rowSpans, categoryWeight);
  return (
    <Table
      className="project-monthly-data-table"
      columns={cols}
      bordered
      rowKey="label"
      dataSource={kpiWeight}
      pagination={false}
    />
  );
};

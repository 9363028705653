/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Timeline, Radio, Space, Checkbox, Input, Form, Button, Avatar, Comment, Tag, Tooltip } from 'antd';
import { Helmet } from 'react-helmet';
import { Card } from '../../components/Card';
import { Collapse } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useContext, useEffect, useState } from 'react';
import { StyledTeamSelect } from '../../components/NoteDrawer.styled';
import { TeamSelect } from '../../components/TeamSelect';
import './FeedbackDashboard.scss';
import { createNote, fetchNotesByTeam } from '../../apis/noteClient';
import { useFetch } from '../../hooks/useFetch';
import { TeamContext } from '../../contexts/TeamContext';
import { UserContext } from '../../contexts/UserContext';
import { nameAbbreviation } from '../../utils/stringUtils';
import { AuthContext } from '../../contexts/AuthContext';
import { DATE_ID_FORMAT, ROLE } from '../../common/constants';
import { hasParentId } from '../../charts/EngagementHeatmap';
import { NoteResponse } from '../../types/note';
import moment from 'moment';
import { ScoreResponse } from '../../types/metrics';
import { fetchScores } from '../../apis/scoreClient';
import { Link } from 'react-router-dom';

const { Panel } = Collapse;
const { TextArea } = Input;

interface IProps {
  onChange?: any;
  onSubmit: any;
  submitting: boolean;
  value: any;
  disabled?: boolean;
}
const Editor = ({ onChange, onSubmit, submitting, value, disabled }: IProps) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} disabled={disabled} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        Add Comment
      </Button>
    </Form.Item>
  </>
);
export const FeedbackDashboard = () => {
  //hooks
  ///=======================================================================
  const [isChecked, setIsChecked] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [newCommentValue, setNewCommentValue] = useState('');
  const { teams } = useContext(TeamContext);
  const { users } = useContext(UserContext);
  const { user } = useContext(AuthContext);
  const [isReplyOpend, setIsReplyOpend] = useState(false);
  const [filterValue, setFilterValue] = useState<number>(1);
  const [noteData, setNoteData] = useState<NoteResponse[]>(null);
  const [reRender, setReRender] = useState(false);
  const [filterDisabled, setFilterDisabled] = useState(true);
  const [filterTeamId, setFilterTeamId] = useState<number[]>([]);
  const [validMonths, setValidMonths] = useState<string[]>([]);
  const [validYears, setValidYears] = useState<string[]>([]);
  const [validMonthsYears, setValidMonthsYears] = useState<number[]>([]);
  const [filterDateId, setFilterDateId] = useState(null);
  const [clickedComment, setClickedComment] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [mobileSize, setMobileSize] = useState(false);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  const breakpoint = 415;
  const { data: scoreData } = useFetch(() => fetchScores({ startDateId: '' }));
  const {
    user: { id },
  } = useContext(AuthContext);
  const isC99User = id === '2';
  //====================================================================
  //data fectching
  const getAllNote = async () => {
    try {
      const noteData = await fetchNotesByTeam({ teamIds: [], dateId: filterDateId });
      setNoteData(noteData);
    } catch (error) {
      console.error(error);
    }
  };
  //======================================================================
  //functions
  const handleMonthChanged = (dateId: string) => {
    setFilterDateId(dateId);
  };
  const handleCheckChange = () => {
    setIsChecked(!isChecked);
  };
  const handleTeamChange = (e) => {
    setSelectedTeam(e);
  };
  const handleSubmit = async () => {
    if (newCommentValue) {
      const data = await createNote({
        teamId: filterTeamId[0],
        description: newCommentValue,
        dateId: parseInt(filterDateId),
        id: null,
      });
      setSubmitting(false);
      setNewCommentValue(undefined);
      if (!selectedTeam) setFilterValue(1);
      setReRender(!reRender);
    }
  };

  const handleReplySubmit = async (teamId: number) => {
    if (value) {
      const data = await createNote({
        teamId,
        description: value,
        dateId: parseInt(filterDateId),
        parentId: clickedComment,
        id: 0,
      });
      setSubmitting(false);
      setValue(undefined);
      if (!selectedTeam) setFilterValue(1);
      setReRender(!reRender);
    }
  };
  //===========================
  //MonthData Initialize
  function getValidMonth(noteData: ScoreResponse[]) {
    let validMonths = Array.from(new Set(noteData?.map((item: ScoreResponse) => item.dateId)));
    return validMonths;
  }
  useEffect(() => {
    const validMonthsList = getValidMonth(scoreData);
    setValidMonthsYears(validMonthsList);
    const years = validMonthsList?.map((year) => moment(year, DATE_ID_FORMAT).format('YYYY'));
    setValidYears(Array.from(new Set(years)));
    setFilterDateId(validMonthsList[0]);
  }, [scoreData]);

  useEffect(() => {
    selectedTeam && setFilterTeamId([selectedTeam]);
  }, [selectedTeam]);

  useEffect(() => {
    (async () => {
      await getAllNote();
    })();
  }, [filterValue, filterTeamId, reRender, filterDateId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    setMobileSize(width < breakpoint);
  }, [width]);

  const handleChange = async (e) => {
    setClickedComment(undefined);
    setNewCommentValue(e.target.value);
  };
  const handleReplyChange = async (e) => {
    setValue(e.target.value);
  };
  const renderActions = (keyValue: number) => {
    const actions = [
      <>
        <span onClick={() => handleReplyClicked(keyValue)} key="comment-basic-reply-to">
          Reply
        </span>
      </>,
    ];
    return !isC99User ? actions : null;
  };
  const handleReplyClicked = (keyValue: number) => {
    setClickedComment(keyValue);
    if (keyValue === clickedComment) setIsReplyOpend(!isReplyOpend);
    else setIsReplyOpend(true);
  };
  const onFilterChange = (e) => {
    setFilterValue(e.target.value);
    const TorF = filterValue === 2 ? true : false;
    TorF && setSelectedTeam(undefined);
    setFilterDisabled(TorF);
  };

  const handleMonthChangeds = (record) => {
    const months = validMonthsYears?.filter((element) => moment(element, DATE_ID_FORMAT).format('YYYY') === record);
    return months.map((month) => moment(month, DATE_ID_FORMAT).format('MMMM'));
  };
  //===================================
  //capg user
  const capgId = users?.filter((item) => item.roles.includes(ROLE.CAPG));
  const idArr = [];
  capgId.forEach((data) => idArr.push(data.id));

  ///needs improvements
  let validMonthYearObj = [];
  validMonthsYears.forEach((element) => {
    const year = moment(element, DATE_ID_FORMAT).format('YYYY');
    const month = moment(element, DATE_ID_FORMAT).format('MMMM');
    let obj = { dateId: element };
    if (!(year in validMonthYearObj)) obj['year'] = year;
    else {
      obj['month'] = month;
    }
    validMonthYearObj.concat(obj);
  });
  //=================================

  const renderTeamTree = (note: NoteResponse) => {
    const parents = teams
      .filter((team) => hasParentId(note.teamId, team.id, teams) && team.id != 1 && team.id !== note.teamId)
      .sort((a, b) => a.type.localeCompare(b.type));

    return (
      <div>
        <span style={{ fontWeight: 400 }}>
          {parents[0]?.name}
          {!parents[1] ? null : (
            <>
              {' / '}
              <Link target="_blank" to={`/engagement/${parents[1].id}`}>
                {parents[1].name}
              </Link>
            </>
          )}
        </span>
        <>
          {' / '}
          <Link target="_blank" to={`/${parents.length > 1 ? 'project' : 'engagement'}/${note.teamId}`}>
            <span style={{ fontWeight: 600 }}>{note.teamName}</span>
          </Link>
        </>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Feedback</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Feedback</span>
          </div>
          <Row className="row">
            {mobileSize === false ? (
              <Col xxl={8} xl={8}>
                <Card className="filter-actions-section" title="">
                  <Space direction="vertical">
                    <Title level={4}>Timeline</Title>
                    {validYears?.map((element) => {
                      const year = moment(element, DATE_ID_FORMAT).format('YYYY');
                      const uniqueMonths = handleMonthChangeds(year);
                      return (
                        <Collapse ghost bordered={false} key={year} defaultActiveKey={element}>
                          <Panel key={element} header={<strong style={{ fontSize: '16px' }}>{year}</strong>}>
                            <Timeline>
                              {uniqueMonths?.map((month) => {
                                const monthFormated = moment(month, 'MMMM').format('MM');
                                return (
                                  <Timeline.Item key={year + monthFormated}>
                                    <Button
                                      style={{ color: year + monthFormated == filterDateId ? 'white' : 'black' }}
                                      onClick={() => handleMonthChanged(`${year + monthFormated}`)}
                                      type={year + monthFormated == filterDateId ? 'primary' : 'text'}
                                    >
                                      {month}
                                    </Button>
                                  </Timeline.Item>
                                );
                              })}
                            </Timeline>
                          </Panel>
                        </Collapse>
                      );
                    })}
                    <Title level={4}>Engagement</Title>
                    <Radio.Group onChange={onFilterChange} value={filterValue}>
                      <Space direction="vertical">
                        <Radio value={1}>All</Radio>
                        <Radio value={2}>Select a destination</Radio>
                      </Space>
                    </Radio.Group>
                    <StyledTeamSelect className="filter-searchbox">
                      <TeamSelect
                        type={['Department', 'Engagement', 'Team']}
                        allowClear
                        showSearch
                        onChange={handleTeamChange}
                        value={selectedTeam}
                        disabled={filterDisabled}
                        placeholder="Search for project team"
                      />
                    </StyledTeamSelect>
                    <Checkbox style={{ paddingBottom: 6, fontSize: 12 }} onChange={handleCheckChange}>
                      {"Show only CAPG's comments"}
                    </Checkbox>
                  </Space>
                </Card>
              </Col>
            ) : (
              <Col className="chart-Program" xxl={8} xl={8}>
                <Collapse accordion>
                  <Panel header="TimeLine" key="1">
                    <Card className="filter-actions-section" title="">
                      <Space direction="vertical">
                        <Title level={4}>Timeline</Title>
                        {validYears?.map((element) => {
                          const year = moment(element, DATE_ID_FORMAT).format('YYYY');
                          const uniqueMonths = handleMonthChangeds(year);
                          return (
                            <Collapse ghost bordered={false} key={year} defaultActiveKey={element}>
                              <Panel key={element} header={<strong style={{ fontSize: '16px' }}>{year}</strong>}>
                                <Timeline>
                                  {uniqueMonths?.map((month) => {
                                    const monthFormated = moment(month, 'MMMM').format('MM');
                                    return (
                                      <Timeline.Item key={year + monthFormated}>
                                        <Button
                                          style={{ color: year + monthFormated == filterDateId ? 'white' : 'black' }}
                                          onClick={() => handleMonthChanged(`${year + monthFormated}`)}
                                          type={year + monthFormated == filterDateId ? 'primary' : 'text'}
                                        >
                                          {month}
                                        </Button>
                                      </Timeline.Item>
                                    );
                                  })}
                                </Timeline>
                              </Panel>
                            </Collapse>
                          );
                        })}
                        <Title level={4}>Engagement</Title>
                        <Radio.Group onChange={onFilterChange} value={filterValue}>
                          <Space direction="vertical">
                            <Radio value={1}>All</Radio>
                            <Radio value={2}>Select a destination</Radio>
                          </Space>
                        </Radio.Group>
                        <StyledTeamSelect className="filter-searchbox">
                          <TeamSelect
                            type={['Department', 'Engagement', 'Team']}
                            allowClear
                            showSearch
                            onChange={handleTeamChange}
                            value={selectedTeam}
                            disabled={filterDisabled}
                            placeholder="Search for project team"
                          />
                        </StyledTeamSelect>
                        <Checkbox onChange={handleCheckChange}>{"Show only CAPG's comments"}</Checkbox>
                      </Space>
                    </Card>
                  </Panel>
                </Collapse>
              </Col>
            )}

            <Col xxl={16} xl={16} className="comment-col">
              <Card className="new-comment-container">
                <Row align="middle">
                  <Col>
                    <Avatar
                      style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle', marginRight: '5px' }}
                      size="large"
                    >
                      {nameAbbreviation(user.fullName)}
                    </Avatar>
                    <span style={{ marginRight: '5px' }}>{user.fullName}</span>
                    {user.roles?.map((role) => {
                      return (
                        <Tag color={role === 'capg' && 'gold'} style={{ marginRight: '5px' }} key={role}>
                          {role.toUpperCase()}
                        </Tag>
                      );
                    })}
                  </Col>
                  <Col flex="auto" style={{ textAlign: 'right' }}>
                    <TeamSelect
                      type={['Department', 'Engagement', 'Team']}
                      allowClear
                      onChange={handleTeamChange}
                      style={{ textAlign: 'left', width: '50%' }}
                      value={selectedTeam}
                      disabled={filterDisabled}
                      showSearch
                      placeholder="Search for project team"
                    />
                  </Col>
                </Row>
                <Tooltip
                  title="Select a team and time to comment. Or Log on to a valid account."
                  placement="bottom"
                  color="cyan"
                >
                  <Comment
                    content={
                      // ===NewComment
                      <Editor
                        disabled={filterDisabled || isC99User}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        submitting={submitting}
                        value={newCommentValue}
                      />
                    }
                  />
                </Tooltip>
              </Card>
              <section className="comment-section">
                {/* Capg Comment*/}
                {isChecked
                  ? noteData
                      ?.filter((x) => {
                        return !selectedTeam || x.teamId === selectedTeam || hasParentId(x.teamId, selectedTeam, teams);
                      })
                      .filter((x) => idArr.includes(x.creatorUserId))
                      .map((note) => {
                        const user = users.find((item) => item.id === note.creatorUserId);

                        return (
                          <div key={note.id} className="comment-section__container">
                            <div className="comment-section__userinfo">
                              <div className="">
                                <Avatar
                                  style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle', marginRight: '10px' }}
                                  size="large"
                                >
                                  {nameAbbreviation(user?.fullName)}
                                </Avatar>
                                <span style={{ marginRight: '5px' }}>{user?.fullName}</span>
                                {user?.roles?.map((role) => {
                                  return (
                                    <Tag color={role === 'capg' && 'gold'} style={{ marginRight: '10px' }} key={role}>
                                      {role.toUpperCase()}
                                    </Tag>
                                  );
                                })}
                                <span>{moment(note.creationTime).format('MMM Do YYYY - 	HH:mm A')}</span>
                              </div>
                              {renderTeamTree(note)}
                            </div>
                            <Comment
                              className="comment-section__text"
                              actions={renderActions(note.id)}
                              content={
                                <>
                                  <p style={{ color: 'orange' }}>{note.description}</p>{' '}
                                </>
                              }
                            >
                              {isReplyOpend && clickedComment === note.id && (
                                <Editor
                                  key={note.id}
                                  onChange={handleReplyChange}
                                  onSubmit={() => handleReplySubmit(note.teamId)}
                                  submitting={submitting}
                                  value={value}
                                />
                              )}
                              {note.listChildNote?.map((childNote) => {
                                const childUser = users.find((item) => item.id === childNote.creatorUserId);
                                return (
                                  <div key={childNote.id} className="comment-section__container">
                                    <div className="reply-comment-section__userinfo">
                                      <Avatar
                                        style={{
                                          backgroundColor: '#00a2ae',
                                          verticalAlign: 'middle',
                                          marginRight: '10px',
                                        }}
                                        size="large"
                                      >
                                        {nameAbbreviation(childUser?.fullName)}
                                      </Avatar>
                                      <span style={{ marginRight: '5px' }}>{childUser?.fullName}</span>
                                      {childUser?.roles?.map((role) => {
                                        return (
                                          <Tag
                                            color={role === 'capg' && 'gold'}
                                            style={{ marginRight: '10px' }}
                                            key={role}
                                          >
                                            {role.toUpperCase()}
                                          </Tag>
                                        );
                                      })}
                                      <span>{moment(childNote.creationTime).format('MMM Do YYYY - 	HH:mm A')}</span>
                                    </div>
                                    <Comment
                                      className="comment-section__text"
                                      content={
                                        <p style={{ color: childNote?.roles?.includes('capg') && '#FFE900' }}>
                                          {childNote.description}
                                        </p>
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </Comment>
                          </div>
                        );
                      })
                  : noteData
                      // {All Comment}
                      ?.filter((x) => {
                        return !selectedTeam || x.teamId === selectedTeam || hasParentId(x.teamId, selectedTeam, teams);
                      })
                      .map((note) => {
                        const user = users.find((item) => item.id === note.creatorUserId);

                        return (
                          <div key={note.id} className="comment-section__container">
                            <>
                              <div className="comment-section__userinfo">
                                <div className="">
                                  <Avatar
                                    style={{ backgroundColor: '#00a2ae', verticalAlign: 'middle', marginRight: '10px' }}
                                    size="large"
                                  >
                                    {nameAbbreviation(user?.fullName)}
                                  </Avatar>
                                  <span style={{ marginRight: '5px', lineBreak: mobileSize ? 'anywhere' : 'normal' }}>
                                    {user?.fullName}
                                  </span>
                                  {user?.roles?.map((role) => {
                                    return (
                                      <Tag color={role === 'capg' && 'gold'} style={{ marginRight: '10px' }} key={role}>
                                        {role.toUpperCase()}
                                      </Tag>
                                    );
                                  })}
                                  <span style={{ whiteSpace: mobileSize ? 'nowrap' : 'normal' }}>
                                    {moment(note.creationTime).format('MMM Do YYYY - 	HH:mm A')}
                                  </span>
                                </div>
                                {renderTeamTree(note)}
                              </div>
                            </>
                            <Comment
                              className="comment-section__text"
                              actions={renderActions(note.id)}
                              content={
                                <>
                                  <p style={{ color: note?.roles?.includes('capg') && 'orange' }}>{note.description}</p>{' '}
                                </>
                              }
                            >
                              {isReplyOpend && note.id === clickedComment && (
                                <Editor
                                  key={note.id}
                                  onChange={handleReplyChange}
                                  onSubmit={() => handleReplySubmit(note.teamId)}
                                  submitting={submitting}
                                  value={value}
                                />
                              )}
                              {note.listChildNote?.map((childNote) => {
                                const childUser = users.find((item) => item.id === childNote.creatorUserId);
                                return (
                                  <div key={childNote.id} className="comment-section__container">
                                    <div className="reply-comment-section__userinfo">
                                      <Avatar
                                        style={{
                                          backgroundColor: '#00a2ae',
                                          verticalAlign: 'middle',
                                          marginRight: '10px',
                                        }}
                                        size="large"
                                      >
                                        {nameAbbreviation(childUser?.fullName)}
                                      </Avatar>
                                      <span style={{ marginRight: '10px' }}>{childUser?.fullName}</span>
                                      {childUser?.roles?.map((role) => {
                                        return (
                                          <Tag
                                            color={role === 'capg' && 'gold'}
                                            style={{ marginRight: '10px' }}
                                            key={role}
                                          >
                                            {role.toUpperCase()}
                                          </Tag>
                                        );
                                      })}
                                      <span>{moment(childNote.creationTime).format('MMM Do YYYY - 	HH:mm A')}</span>
                                    </div>
                                    <Comment
                                      className="comment-section__text"
                                      content={
                                        <p style={{ color: childNote.roles?.includes('capg') && 'orange' }}>
                                          {' '}
                                          {childNote.description}
                                        </p>
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </Comment>
                          </div>
                        );
                      })}
              </section>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};

import { fetchAsync } from '../utils/fetch';

const fetchMeasureTemplate = async (dateId: number) => {
  const result = await fetchAsync(`/api/measure/template/${dateId}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};
export default fetchMeasureTemplate;

import { Column } from '@ant-design/charts';
import { ColumnConfig } from '@ant-design/charts/es';
import { Empty } from 'antd';
import React, { useContext } from 'react';
import {
  DETRACTOR_LABEL,
  PASSIVE_LABEL,
  PROMOTER_LABEL,
  PROMOTER_COLOR,
  DETRACTOR_COLOR,
  PASSIVE_COLOR,
} from '../common/constants';
import { NpsDataContext } from '../contexts/NpsDataContext';

export const DepartmentNpsPerformance = () => {
  const { departmentScores } = useContext(NpsDataContext);
  if (!departmentScores?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  let npsData = [];

  departmentScores.forEach((item) => {
    let promoterItem = { type: PROMOTER_LABEL, name: item.name, value: item.promoterCount };
    let passiveItem = { type: PASSIVE_LABEL, name: item.name, value: item.passiveCount };
    let detractorItem = { type: DETRACTOR_LABEL, name: item.name, value: item.detractorCount };

    npsData.push(detractorItem, passiveItem, promoterItem);
  });

  var config: ColumnConfig = {
    data: npsData,
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
    height: 300,
    legend: {
      position: 'top',
      flipPage: false,
    },
    label: {
      content: (item) => {
        var percent = item.value;
        return percent === 0 ? '' : `${Math.round(percent * 100)}%`;
      },
      position: 'middle',
    },
    color: (_ref) => {
      var type = _ref.type;
      if (type === PROMOTER_LABEL) {
        return PROMOTER_COLOR;
      } else if (type === PASSIVE_LABEL) {
        return PASSIVE_COLOR;
      }
      return DETRACTOR_COLOR;
    },
    yAxis: {
      label: {
        formatter: (_, item) => {
          return `${item.value * 100}%`;
        },
      },
    },
  };

  if (npsData.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return <Column {...config} />;
};

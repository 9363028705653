import styled from 'styled-components';

export const FootNoteContainer = styled.div`
  margin-top: 11px;
`;

export const FootNote = styled.div`
  font-size: 11px;
`;

export const MonthlyDataToolbar = styled.div`
  margin-bottom: 10px;
`;

import { Line } from '@ant-design/charts';
import { minBy, orderBy, startCase } from 'lodash';
import { useContext } from 'react';
import moment from 'moment';

import { ProjectDataContext } from '../contexts/ProjectDataContext';
import { AggregatedScore } from '../types/metrics';
import { DATE_ID_FORMAT } from '../common/constants';
import { Empty } from 'antd';
import { targetAnnotation } from '../utils/chartUtils';
import { useFetch } from '../hooks/useFetch';
import { fetchCategory } from '../apis/fetchCategory';

export const ProjectTrending = () => {
  const { scores } = useContext(ProjectDataContext);
  const endDateId = moment().format(DATE_ID_FORMAT);
  const startDateId = moment().subtract(12, 'months').format(DATE_ID_FORMAT);
  const scoresWithin12Months = scores.filter((item) => +endDateId >= item.dateId && item.dateId >= +startDateId);
  const { data: categoryList } = useFetch(() => fetchCategory());

  const data = orderBy(scoresWithin12Months, (item) => item.dateId).flatMap((item) => {
    const month = moment(item.dateId, DATE_ID_FORMAT).format('MMM YY');
    if (item.categoryData.length === 0) return [];
    else {
      var temp = [];
      categoryList?.forEach((category) =>
        temp.push({
          date: month,
          category: category.name,
          value: Number(item.categoryData?.find((x) => x.categoryId === category.id)?.score?.toFixed(2)),
        })
      );
      return temp;
    }
  });
  if (!data?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <Line
      data={data}
      height={300}
      yField="value"
      xField="date"
      seriesField="category"
      point={{
        shape: 'diamond',
        size: 5,
      }}
      yAxis={{
        max: 1.05,
        tickInterval: 0.05,
        min: Math.min(minBy(data, (item) => item.value)?.value, 0.5),
      }}
      label={{}}
      annotations={targetAnnotation(scores.length)}
      legend={{
        position: 'top',
        flipPage: false,
      }}
    />
  );
};

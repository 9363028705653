import { fetchAsync } from '../utils/fetch';

const fetchTeamWeightTemplate = async (DateId: number) => {
  const result = await fetchAsync(`/api/weight/template/${DateId}`, {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};
export default fetchTeamWeightTemplate;

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
}

export const postTeamWeightTemplate = async (dateId: number, file: File) => {
  const data = new FormData();
  data.append('file', file);
  const result = fetchAsync(`/api/weight/import/${dateId}`, {
    method: 'POST',
    body: data,
    type: 'blob',
  });
  return result;
};

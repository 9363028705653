import { Button, Card, Input, Select, Tag, notification } from 'antd';
import { TablePaginationConfig, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState, useContext } from 'react';
import { fetchRoles } from '../apis/fetchRole';
import { deleteUser, fetchUsers, updateUser, createUser } from '../apis/userClient';
import { EditableColumn, EditableTable } from '../components/EditableTable';
import { useFetch } from '../hooks/useFetch';
import { PageTitle, ToolbarContainer } from '../layouts/AdminLayout.styled';
import { NewUserModel, UserResponse } from '../types/user';
import { deleteItem, insertItem, updateItem } from '../utils/tableUtils';
import { USER_PAGE_NUMBER } from '../common/constants';
import { ResetPassword } from '../components/ResetPassword';
import { Helmet } from 'react-helmet';
import { TeamContext } from '../contexts/TeamContext';
import { SearchOutlined } from '@ant-design/icons';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

export const ManageUsers = () => {
  const [data, setData] = useState<UserResponse[]>();
  const { data: originalData, loading, error: fetchError } = useFetch(fetchUsers);
  const [addUserDialog, showAddUserDialog] = useState(false);
  const [userId, setUserId] = useState<number>();
  const [form] = Form.useForm<NewUserModel>();
  const [page, setPage] = useState(1);
  const { data: roles } = useFetch(fetchRoles);
  const [isShowDiaglog, setIsShowDiaglog] = useState(false);
  const { teams } = useContext(TeamContext);

  const openNotification = (statusCode: number, message: string) => {
    notification.error({
      message: `Error: ${statusCode}`,
      description: message,
      duration: 2,
    });
  };
  const options = roles?.map((item) => ({ label: item.name, value: item.name }));
  const teamoptions = teams
    ?.filter((x) => x.type === 'Engagement')
    .map((item) => ({ label: item.name, value: item.name }));

  const functionFilter = (selectedKeys, setSelectedKeys, confirm) => {
    return (
      <>
        <Input
          placeholder="Type search name"
          value={selectedKeys[0]}
          onChange={(data) => {
            setSelectedKeys(data.target.value ? [data.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        />
      </>
    );
  };

  const columns: EditableColumn<UserResponse>[] = [
    {
      title: '#',
      width: '10px',
      align: 'center',
      render: (value, record, index) => (page - 1) * USER_PAGE_NUMBER + index + 1,
    },
    {
      title: 'UserName',
      dataIndex: 'userName',
      editable: false,
      render: (text, record) => record.userName,
      rules: [{ required: true, message: 'This field is required' }],
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) =>
        functionFilter(selectedKeys, setSelectedKeys, confirm),
      // eslint-disable-next-line react/display-name
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.userName.toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      editable: true,
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      editable: true,
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      editable: true,
      inputType: 'select',
      inputProps: {
        mode: 'multiple',
      },
      rules: [{ required: true, message: 'At least one role is required' }],
      options: options,
      render: (value: string, record: UserResponse) =>
        record && record.roles.map((role) => <Tag key={role}>{role}</Tag>),
    },
    {
      title: 'Engagement',
      dataIndex: 'engagementNames',
      editable: true,
      inputType: 'select',
      inputProps: {
        mode: 'multiple',
      },
      options: teamoptions,
      render: (value: string, record: UserResponse) =>
        record && record.engagementNames.map((item) => <Tag key={item}>{item}</Tag>),
    },
  ];

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  if (loading) {
    return null;
  }

  if (fetchError) {
    return <div>{JSON.stringify(fetchError)}</div>;
  }

  const onSave = async (id: number, { email, fullName, userName, roles, engagementNames }: UserResponse) => {
    try {
      await updateUser(id, { email, fullName, userName, roles, engagementNames });
      const newData = updateItem({ id, email, fullName, userName, roles, engagementNames }, data);
      setData(newData);
    } catch (error) {
      openNotification(400, 'Update failed!');
    }
  };

  const onDelete = async (id: number) => {
    try {
      await deleteUser(id);
      const newData = deleteItem(id, data);
      setData(newData);
    } catch (error) {
      openNotification(400, 'Delete failed!');
    }
  };

  const onChangePassword = async (id: number) => {
    setUserId(id);
    setIsShowDiaglog(true);
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    try {
      const { email, password, userName, fullName, roles, engagementNames } = form.getFieldsValue();
      const newUser = await createUser({
        email,
        password,
        userName,
        fullName,
        roles,
        engagementNames,
      });
      form.resetFields();
      const newData = insertItem(newUser, data);
      setData(newData);
      showAddUserDialog(false);
    } catch (error) {
      openNotification(400, 'Duplicate username or email');
    }
  };

  const onChange = (pagination: TablePaginationConfig) => {
    setPage(pagination.current);
  };

  return (
    <Card>
      <Helmet>
        <title>Manage Users</title>
      </Helmet>
      <PageTitle>
        <Title level={3}>Manage Users</Title>
      </PageTitle>
      <ToolbarContainer>
        <Button type="primary" onClick={() => showAddUserDialog(true)}>
          Add New User
        </Button>
      </ToolbarContainer>

      <EditableTable
        data={data}
        columns={columns}
        onChange={onChange}
        onSave={onSave}
        onDelete={onDelete}
        onChangePassword={onChangePassword}
        isChangePassword={true}
      />
      <Modal title="Add New User" visible={addUserDialog} onOk={onSubmit} onCancel={() => showAddUserDialog(false)}>
        <Form {...layout} className="add-user-modal" form={form} name="basic" initialValues={{ remember: true }}>
          <Form.Item label="Username" name="userName" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Full Name" name="fullName" rules={[{ required: true, message: 'This field is required!' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: 'This field is required!' },
              { min: 6, message: 'The verify password must be minimum 6 characters.' },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label="Role" name="roles" rules={[{ required: true, message: 'This field is required!' }]}>
            <Select mode="multiple" options={options} />
          </Form.Item>
          <Form.Item label="Engagement" name="engagementNames">
            <Select mode="multiple" options={teamoptions} />
          </Form.Item>
        </Form>
      </Modal>
      <ResetPassword id={userId} isOpenDialog={isShowDiaglog} setVisible={setIsShowDiaglog} />
    </Card>
  );
};

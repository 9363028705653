import { Row, Col, DatePicker, Popover, Button } from 'antd';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useContext, useState } from 'react';
import { useFetch } from '../hooks/useFetch';

import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { NpsStatus } from '../charts/NpsStatus';
import { Card } from '../components/Card';
import { DATE_ID_FORMAT, MIN_DATE_ID, MONTH_PICKER_FORMAT } from '../common/constants';
import { NpsDataContext, NpsDataContextProvider } from '../contexts/NpsDataContext';
import { useHistory, useLocation } from 'react-router';
import { NpsPerformance } from '../charts/NpsPerformance';
import { DepartmentNpsPerformance } from '../charts/DepartmentNpsPerformance';
import { EngagementNps } from '../charts/EngagementNps';
import { EngagementNpsTable } from '../charts/EngagementNpsTable';
import { EngagementNpsColumn } from '../charts/EngagementNpsColumn';
import './NpsDashboard.scss';
import { SentimentClientScore } from '../charts/SentimentClientScore';
import { SentimentCategoriesByDep } from '../charts/SentimentCategoriesByDep';
import { SentimentCategoriesByTeam } from '../charts/SentimentCategoriesByTeam';
import downloadFileFromBlob from '../utils/downloadFileFromBlob';
import { ExportNPS } from '../apis/scoreClient';
import Title from 'antd/lib/typography/Title';

interface INPSPickerProps {
  dateId?: string;
}

const NpsFormula = <div>Total % of promoters – total % of detractors = net promoter score</div>;

const NPSDatePicker = ({ dateId }: INPSPickerProps) => {
  const history = useHistory();
  const { programScore } = useContext(NpsDataContext);
  const date = dateId || programScore?.dateId;
  const month = date ? moment(`${date}`, DATE_ID_FORMAT) : null;
  return (
    <DatePicker
      value={month}
      disabledDate={(current) => current.diff(moment(MIN_DATE_ID)) < 0}
      onChange={(e) => {
        e && history.push(`/npsdashboard?dateId=${e.format(DATE_ID_FORMAT)}`);
      }}
      format={MONTH_PICKER_FORMAT}
      picker="month"
      allowClear={false}
    />
  );
};

export const NpsDashboard = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const dateId = params.get('dateId');
  const month = dateId ? moment(dateId, DATE_ID_FORMAT) : null;

  const [isLoadingDownloadEngagement, setIsLoadingDownloadEngagement] = useState<boolean>(false);
  const [isLoadingDownloadTeam, setIsLoadingDownloadTeam] = useState<boolean>(false);

  const ExportData = async (type: string) => {
    try {
      type === 'Engagement' ? setIsLoadingDownloadEngagement(true) : setIsLoadingDownloadTeam(true);
      const result = await ExportNPS(Number(dateId), type);
      downloadFileFromBlob(result.blob, result.fileName);
    } catch (error) {
    } finally {
      type === 'Engagement' ? setIsLoadingDownloadEngagement(false) : setIsLoadingDownloadTeam(false);
    }
  };

  return (
    <NpsDataContextProvider month={month?.toDate()}>
      <Helmet>
        <title>NPS Analysis</title>
      </Helmet>
      <div className="page-home page-container">
        <section className="section-container">
          <div className="section-title-container">
            <span className="section-title">Program Overview</span>
            <NPSDatePicker dateId={dateId} />
          </div>
          <Row gutter={24} className="row fullwitdh-chart">
            <Col xs={24} sm={12} xxl={5} xl={6}>
              <Card title="Program NPS">
                <Popover title="The NPS Calculation" content={NpsFormula}>
                  <InfoCircleOutlined className="npsIcon" />
                </Popover>
                <NpsStatus />
              </Card>
            </Col>
            <Col xs={24} sm={12} xxl={5} xl={8}>
              <Card title="NPS by Categories">
                <NpsPerformance />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={10}>
              <Card title="Department NPS Performance">
                <DepartmentNpsPerformance />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={12}>
              <Card title="Engagement NPS Distribution">
                <EngagementNps type="Engagement" />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={12}>
              <Card title="Team NPS Distribution">
                <EngagementNps type="Team" />
              </Card>
            </Col>
            <Col xxl={9} xl={12}>
              <Card>
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Top Engagement NPS Improvement/Decline
                  </Title>
                  <Button onClick={() => ExportData('Engagement')} loading={isLoadingDownloadEngagement}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <EngagementNpsTable type="Engagement" />
              </Card>
            </Col>
            <Col xxl={8} xl={12}>
              <Card>
                <Row>
                  <Title level={5} className="month-title" style={{ margin: 'auto', marginBottom: '20px' }}>
                    Top Team NPS Improvement/Decline
                  </Title>
                  <Button onClick={() => ExportData('Team')} loading={isLoadingDownloadTeam}>
                    <DownloadOutlined />
                  </Button>
                </Row>
                <EngagementNpsTable type="Team" />
              </Card>
            </Col>
            <Col xs={24} xxl={12}>
              <Card title="Engagement NPS">
                <div className="npsBarChar">
                  <EngagementNpsColumn type="Engagement" />
                </div>
              </Card>
            </Col>
            <Col xs={24} xxl={12}>
              <Card title="Team NPS">
                <div className="npsBarChar">
                  <EngagementNpsColumn type="Team" />
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} xxl={5} xl={6}>
              <Card title="Client Sentiment Score">
                <SentimentClientScore />
              </Card>
            </Col>
            <Col xs={24} xxl={7} xl={10}>
              <Card title="Sentiment Categories by Department">
                <SentimentCategoriesByDep />
              </Card>
            </Col>
            <Col xs={24}>
              <Card title="Sentiment Categories by Team">
                <SentimentCategoriesByTeam />
              </Card>
            </Col>
          </Row>
        </section>
      </div>
    </NpsDataContextProvider>
  );
};

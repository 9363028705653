import { Column } from '@ant-design/charts';
import { NpsDataContext } from '../contexts/NpsDataContext';
import { ColumnConfig } from '@ant-design/charts/es';
import { useContext } from 'react';
import { Empty } from 'antd';
import { getSentimentCategory, getSentimentColor } from '../utils/chartUtils';

export const SentimentCategoriesByTeam = () => {
  const { sentimentNpsData } = useContext(NpsDataContext);

  if (!sentimentNpsData || sentimentNpsData.clientSentiment == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  let data = sentimentNpsData.sentimentCategoriesByTeam;
  let npsData = [];
  for (let i = 0; i < data?.length; i++) {
    npsData.push({
      nps: data[i].name,
      value: data[i].score,
      category: getSentimentCategory(data[i].posNeg),
    });
  }

  var config: ColumnConfig = {
    seriesField: 'category',
    xField: 'nps',
    yField: 'value',
    xAxis: {
      label: {
        style: {
          textAlign: 'left',
          stroke: '#ddd',
        },
        autoHide: false,
        rotate: 0.8,
      },
    },
    color: ({ category }) => {
      const label = category.split(' (')[0];
      return getSentimentColor(label);
    },
    columnWidthRatio: 0.4,
    label: {},
    tooltip: {
      formatter: (datum) => {
        return {
          name: `${datum.category}`,
          value: datum.value,
        };
      },
    },
    data: npsData,
    legend: {
      marker: {
        symbol: 'circle',
      },
      position: 'top',
      flipPage: false,
    },
  };

  return <Column {...config} height={300} />;
};

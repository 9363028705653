import { CategoryModel, CategoryResponse, CategoryWeightModelResponse, CategoryWeightResponse } from '../types/metrics';
import { fetchAsync } from '../utils/fetch';

export const fetchCategory = (): Promise<CategoryResponse[]> => fetchAsync('/api/category');

export const updateCategory = (id: number, metric: CategoryModel): Promise<CategoryResponse> =>
  fetchAsync(`/api/category/${id}`, {
    method: 'PUT',
    body: metric,
  });

export const deleteCategory = (id: number): Promise<void> =>
  fetchAsync(`/api/category/${id}`, {
    method: 'DELETE',
  });

export const createCategory = (metric: CategoryModel): Promise<CategoryResponse> =>
  fetchAsync(`/api/category`, {
    method: 'POST',
    body: metric,
  });

export const fetchCategoryByteam = (DateId: number, teamId: number): Promise<CategoryWeightResponse[]> =>
  fetchAsync(`/api/category/${teamId}/dateId?dateId=${DateId}`, {
    method: 'GET',
    type: 'blob',
  });

export const createCategoryWeight = (
  dateId: number,
  teamId: number,
  category: CategoryWeightModelResponse[]
): Promise<void> =>
  fetchAsync(`/api/weight/Category-Weight/${dateId}/${teamId}`, {
    method: 'POST',
    body: category,
  });

import { NoteModel, NoteQuery, NoteResponse } from '../types/note';
import { fetchAsync } from '../utils/fetch';

export const fetchNotesByTeam = (payload: NoteQuery): Promise<NoteResponse[]> =>
  fetchAsync(`/api/note/filter`, {
    method: 'POST',
    body: payload,
  });

export const fetchNotes = (): Promise<NoteResponse[]> => fetchAsync(`/api/note`);

export const deleteNote = (id: number): Promise<void> =>
  fetchAsync(`/api/note/${id}`, {
    method: 'DELETE',
  });

export const createNote = (note: NoteModel): Promise<NoteResponse> =>
  fetchAsync(`/api/note`, {
    method: 'POST',
    body: note,
  });

export const updateNote = (id: number, Note: NoteModel): Promise<NoteResponse> =>
  fetchAsync(`/api/note/${id}`, {
    method: 'PUT',
    body: Note,
  });

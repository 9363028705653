import { Bar } from '@ant-design/charts';
import { Empty } from 'antd';
import { useContext } from 'react';
import { BELOW_TARGET, COLOR_ABOVE_TARGET, COLOR_BELOW_TARGET, COLOR_ON_TARGET } from '../common/constants';
import { ProgramDataContext } from '../contexts/ProgramDataContext';
import { customTargetLegend, getTargetColor, getTargetLabel } from '../utils/chartUtils';

export const SolutionSetScores = () => {
  const { departmentScores } = useContext(ProgramDataContext);

  if (!departmentScores?.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const data = departmentScores.map((item) => ({ ...item, category: getTargetLabel(Number(item.score.toFixed(2))) }));
  data.map((item) => item.score == Number(item.score.toFixed(2)));
  return (
    <div className="project-metrics-container">
      <Bar
        height={300}
        data={data}
        xField="score"
        yField="name"
        seriesField="category"
        label={{
          position: 'middle',
        }}
        legend={{
          position: 'top',
          items: customTargetLegend,
        }}
        maxBarWidth={50}
        color={({ category }) => {
          return getTargetColor(category);
        }}
      />
    </div>
  );
};

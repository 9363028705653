import { ScoreCardData } from '../types/metrics';
import { TeamResponse } from '../types/team';
import { aggregateByDate, toTeamScore } from './programData';

export const transform = (data: ScoreCardData[], teams: TeamResponse[], dateId: string) => {
  const aggregation = aggregateByDate(data, dateId);

  return {
    teamScores: toTeamScore(aggregation, teams),
  };
};

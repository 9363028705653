import { useContext } from 'react';
import { NpsDataContext } from '../contexts/NpsDataContext';
import { Pie } from '@ant-design/charts';
import { PieConfig } from '@ant-design/charts/es';
import {
  PROMOTER_COLOR,
  DETRACTOR_COLOR,
  PASSIVE_COLOR,
  PROMOTER_LABEL,
  DETRACTOR_LABEL,
  PASSIVE_LABEL,
} from '../common/constants';
import { Empty } from 'antd';

export const NpsPerformance = () => {
  const {
    programScore: { promoterCount, passiveCount, detractorCount },
  } = useContext(NpsDataContext);

  if (promoterCount == null && passiveCount == null && detractorCount == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  const data = [
    {
      label: DETRACTOR_LABEL,
      value: detractorCount,
    },
    {
      label: PASSIVE_LABEL,
      value: passiveCount,
    },
    {
      label: PROMOTER_LABEL,
      value: promoterCount,
    },
  ];
  const config: PieConfig = {
    label: {
      type: 'inner',
      offset: '50%',
      style: { textAlign: 'center' },
      autoRotate: false,

      content: function content(_ref) {
        var percent = _ref.percent;
        return percent === 0 ? '' : `${Math.round(percent * 100)}%`;
      },
    },
    angleField: 'value',
    colorField: 'label',
    data: data,
    legend: {
      position: 'top',
      flipPage: false,
    },
  };

  return (
    <Pie
      {...config}
      height={300}
      color={({ label }) => {
        switch (label) {
          case DETRACTOR_LABEL:
            return DETRACTOR_COLOR;
          case PROMOTER_LABEL:
            return PROMOTER_COLOR;
          case PASSIVE_LABEL:
            return PASSIVE_COLOR;
          default:
            return '';
        }
      }}
    />
  );
};

import { createContext, useContext } from 'react';
import moment from 'moment';
import { useFetch } from '../hooks/useFetch';
import { IProgramData, ScoreQuery } from '../types/metrics';
import { DATE_ID_FORMAT } from '../common/constants';
import { fetchScores } from '../apis/scoreClient';
import { Spin } from 'antd';
import { TeamContext } from './TeamContext';
import { transform } from '../utils/programData';

export const ProgramDataContext = createContext<IProgramData>(null);

interface IProps {
  month?: Date;
  children: React.ReactNode;
}

export const ProgramDataContextProvider = ({ month, children }: IProps) => {
  const { teams } = useContext(TeamContext);
  let query: ScoreQuery;
  let endDateId = month ? moment(month).format(DATE_ID_FORMAT) : null;
  if (month) {
    const startDateId = moment(month).clone().subtract(1, 'month').format(DATE_ID_FORMAT);
    query = {
      startDateId,
      endDateId,
    };
  } else {
    query = {
      monthsLimit: 2,
    };
  }
  const { data, loading, error } = useFetch(() => fetchScores(query), [month]);
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  return (
    <Spin spinning={loading}>
      <ProgramDataContext.Provider value={transform(data, teams, endDateId)}>{children}</ProgramDataContext.Provider>
    </Spin>
  );
};

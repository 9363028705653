import styled from 'styled-components';

export const StyledColorGradient = styled.div`
  display: inline-block;
  height: 30px;
`;

interface IProps {
  lastItem?: boolean;
}

export const StyledColorLabel = styled.div<IProps>`
  display: inline-block;
  color: #888;
  font-size: 12px;
  line-height: 1em;

  ${({ lastItem }: IProps) =>
    lastItem &&
    `
  display: inline-flex;
    justify-content: space-between;
  `}
`;

export const StyledEndLabel = styled.span`
  text-align: right;
`;

export const ColorLabelContainer = styled.div`
  line-height: 1em;
`;

import { Empty, Switch, Table } from 'antd';
import { useContext, useState } from 'react';
import moment from 'moment';
import { AggregatedScore, MonthlyData } from '../types/metrics';
import { ABOVE_TARGET, BELOW_TARGET, DATE_ID_FORMAT } from '../common/constants';
import { ColumnsType } from 'antd/lib/table';
import './MonthlyData.scss';
import { get, orderBy } from 'lodash';
import { MonthlyDataToolbar } from './EngagementMonthlyData.styled';
import { useEngagementData } from '../hooks/useEngagementData';
import { TeamResponse } from '../types/team';
import { TeamContext } from '../contexts/TeamContext';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const DATE_COL_WIDTH = 100;
const CHILD_TEAM_COL_WIDTH = 100;
const LABEL_COL_WIDTH = 200;

const getCellAttributes = (score: number): any => {
  if (score == null) {
    return { 'data-target': 'null' };
  }

  if (score < BELOW_TARGET) {
    return { 'data-target': 'below' };
  }

  if (score < ABOVE_TARGET) {
    return { 'data-target': 'on' };
  }

  return { 'data-target': 'above' };
};

const getRowSpans = (arr, key) => {
  let sameValueLength = 0;
  const rowSpans = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    if (i === 0) {
      rowSpans[i] = sameValueLength + 1;
      continue;
    }
    if (arr[i][key] === arr[i - 1][key]) {
      rowSpans[i] = 0;
      sameValueLength++;
    } else {
      rowSpans[i] = sameValueLength + 1;
      sameValueLength = 0;
    }
  }
  return rowSpans;
};

const renderChildCell = (value: number, record: MonthlyData, dataIndex: any[]) => {
  const data = get(record, dataIndex);
  return <div title={`Actual: ${value ?? 'N/A'}, Score⁽*⁾: ${data?.score ?? 'N/A'}`}>{value}</div>;
};

const createMonthlyColumns = (dates: number[], rowSpans: any[], childTeams?: TeamResponse[]) => {
  const dateCols: ColumnsType = [
    {
      title: 'Category',
      dataIndex: 'theme',
      width: LABEL_COL_WIDTH,
      key: 'theme',
      fixed: 'left',
      render: (value, record: MonthlyData, index) => {
        const obj = {
          children: record.theme,
          props: { rowSpan: rowSpans[index] },
        };
        return obj;
      },
      onCell: (record: MonthlyData) => ({
        style: {
          backgroundColor: record.color,
        },
      }),
    },
    {
      dataIndex: 'label',
      title: 'KPI',
      fixed: 'left',
      width: LABEL_COL_WIDTH,
      className: 'monthly-data-name-column',
      render: (value, record: MonthlyData, index) => record.type,
      onCell: (record: MonthlyData) => ({
        style: {
          backgroundColor: record.color,
        },
      }),
    },
  ];

  const sortedDates = orderBy(dates, (a) => a, 'desc');

  sortedDates.forEach((dateId) => {
    const date = moment(`${dateId}`, DATE_ID_FORMAT);
    // Check if Engagement has actual measure of its own
    const dateColGroup: ColumnsType<any> = [];

    // Add child team actual columns
    const colgroupClass = dateId % 2 === 0 ? 'monthly-column-group-even' : 'monthly-column-group-odd';
    if (childTeams?.length > 0) {
      childTeams.forEach((item) => {
        const colHeaderRender = () => (
          <Link target="_blank" to={`/project/${item.id}?dateId=${dateId}`}>
            {item.name}
          </Link>
        );

        dateColGroup.push({
          dataIndex: [dateId, 'children', item.name, 'actual'],
          title: colHeaderRender,
          width: CHILD_TEAM_COL_WIDTH,
          align: 'center',
          ellipsis: true,
          className: classNames('monthly-data-child-column', colgroupClass),
          onCell: (record: MonthlyData) => getCellAttributes(record[dateId]?.children?.[item.name]?.score),
          render: (val, record) => renderChildCell(val, record, [dateId, 'children', item.name]),
        });
      });
      dateCols.push({
        title: date.format('MMM-YY'),
        children: dateColGroup,
        className: classNames('monthly-data-column-group', colgroupClass),
      });
    } else {
      dateCols.push({
        dataIndex: [dateId, 'score'],
        title: date.format('MMM-YY'),
        width: DATE_COL_WIDTH,
        align: 'center',
        className: classNames('monthly-data-engagement-column', 'monthly-data-column', colgroupClass),
        onCell: (record: AggregatedScore) => getCellAttributes(record[dateId]?.score),
        render: (val: number) => val?.toFixed(2),
      });
    }
  });

  return dateCols;
};

export const EngagementMonthlyData = () => {
  const [showTeamScores, setShowTeamScores] = useState(false);
  const { teams } = useContext(TeamContext);
  const { monthlyData, teamId, dates } = useEngagementData();
  const childTeams = teams.filter((item) => `${item.parentId}` === teamId);
  if (dates.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const rowSpans = getRowSpans(monthlyData, 'theme');
  const cols = showTeamScores
    ? createMonthlyColumns(dates, rowSpans, childTeams)
    : createMonthlyColumns(dates, rowSpans);
  const hasChildTeams = childTeams?.length > 0;
  const scrollX = `calc(${LABEL_COL_WIDTH}px + 50%)`;

  return (
    <div>
      {hasChildTeams && (
        <MonthlyDataToolbar>
          <Switch checked={showTeamScores} onChange={() => setShowTeamScores(!showTeamScores)} />
          <span className="title"> Show Child team</span>
        </MonthlyDataToolbar>
      )}
      <Table
        className={classNames('project-monthly-data-table', {
          'show-team-scores': showTeamScores,
        })}
        columns={cols}
        bordered
        rowKey="label"
        scroll={{ x: scrollX }}
        pagination={false}
        dataSource={monthlyData}
      />
    </div>
  );
};

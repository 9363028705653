import { useContext, useState } from 'react';
import { convertToRaw, EditorState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { Button } from 'antd';
import { createGuideline } from '../apis/guidelineClient';
import { GuidelineQuery } from '../types/guideline';
import { GuidelineContext } from '../contexts/GuidelineContext';
import 'draft-js/dist/Draft.css';

interface IProps {
  data?: any;
  setMode: (bool: boolean) => void;
  editMode?: boolean;
}
export const TextEditor = ({ data, setMode, editMode = true }: IProps) => {
  const { setGuideline } = useContext(GuidelineContext);
  const [contentState, setContentState] = useState(data);
  const onContentStateChange: Function = (state) => {
    setContentState(state);
  };
  const onCancel = () => {
    setMode(false);
  };
  const onSave = async () => {
    let guidelinePayload: GuidelineQuery[] = [
      {
        type: 'html',
        text: draftToHtml(convertToRaw(contentState.getCurrentContent())),
      },
    ];
    try {
      const data = await createGuideline([...guidelinePayload]);
      setGuideline(data);
    } catch (error) {
      console.log(error);
    }
    setMode(false);
  };
  return (
    <>
      <div className="edit-action-buttons">
        <Button size="large" className="cancel-action" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="large" type="primary" className="save-action" onClick={onSave}>
          Save
        </Button>
      </div>
      <Editor
        editorState={contentState}
        wrapperClassName="editor-wrapper"
        editorClassName="editor-box"
        toolbarClassName="editor-toolbar"
        onEditorStateChange={onContentStateChange}
        toolbar={{
          image: false,
        }}
        onTab={(e) => {
          const tabCharacter = '              ';
          e.preventDefault();
          let currentState = contentState;
          let newContentState = Modifier.replaceText(
            currentState.getCurrentContent(),
            currentState.getSelection(),
            tabCharacter
          );

          setContentState(EditorState.push(currentState, newContentState, 'insert-characters'));
        }}
      />
    </>
  );
};

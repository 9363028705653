/* eslint-disable react/self-closing-comp */
import { Gauge, GaugeConfig } from '@ant-design/charts';
import { Empty, Tooltip } from 'antd';
import { round } from 'lodash';
import { useContext } from 'react';
import {
  DETRACTOR_COLOR,
  EXCELLENT_COLOR,
  GAUGE_DETRACTOR,
  GAUGE_EXCELLENT,
  PROMOTER_COLOR,
} from '../common/constants';
import { NpsDataContext } from '../contexts/NpsDataContext';
import './SentimentClientScore.scss';

interface IProps {
  pos: number;
  neu: number;
  neg: number;
}

export const TooltipData = ({ pos, neu, neg }: IProps) => {
  return (
    <div>
      <div className="title-tooltip-sentiment">Client Sentiment Score</div>
      <ul className="tooltip-data-list">
        <li className="span-tooltip-sentiment">
          <span className="span-maker notgood"></span>
          <span>Not Good</span>
          <span className="sentiment-value">{neg}</span>
        </li>
        <li className="span-tooltip-sentiment">
          <span className="span-maker neu"></span>
          <span>Neutral</span>
          <span className="sentiment-value">{neu}</span>
        </li>
        <li className="span-tooltip-sentiment">
          <span className="span-maker good"></span>
          <span>Good</span>
          <span className="sentiment-value">{pos}</span>
        </li>
      </ul>
    </div>
  );
};

export const SentimentClientScore = () => {
  const { sentimentNpsData } = useContext(NpsDataContext);

  if (!sentimentNpsData || sentimentNpsData.clientSentiment == null) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }
  var input = sentimentNpsData.clientSentiment?.clientSentimentScore;
  var formatInput = (input + 1) / 2;
  var config: GaugeConfig = {
    axis: {
      label: {
        formatter: function formatter(v) {
          return round((Number(v) - 0.5) * 2, 2);
        },
      },
      subTickLine: { count: 3 },
    },
    percent: formatInput,
    statistic: {
      content: {
        style: {
          fontSize: '36px',
          lineHeight: '36px',
        },
        formatter: () => {
          return `${round(input, 2)}`;
        },
      },
    },
  };

  return (
    <div className="gauge-container">
      <Tooltip
        placement="right"
        title={
          <TooltipData
            pos={sentimentNpsData.clientSentiment?.clientSentimentPos}
            neu={sentimentNpsData.clientSentiment?.clientSentimentNeutral}
            neg={sentimentNpsData.clientSentiment?.clientSentimentNeg}
          />
        }
      >
        <Gauge
          {...config}
          height={300}
          range={{
            ticks: [0, GAUGE_DETRACTOR, GAUGE_EXCELLENT, 1],
            color: [DETRACTOR_COLOR, PROMOTER_COLOR, EXCELLENT_COLOR],
          }}
        />
        {sentimentNpsData?.clientSentimentScoreMoM != null && (
          <div className="gauge-text">
            <span
              className={`kpi-badge ${sentimentNpsData.clientSentimentScoreMoM < 0 ? 'kpi-decrease' : 'kpi-increase'}`}
            >
              <span className="kpi-indicator">{sentimentNpsData.clientSentimentScoreMoM < 0 ? '▼' : '▲'}</span>
              <span className="kpi-value">{+sentimentNpsData.clientSentimentScoreMoM.toFixed(2)}</span>
              <span className="kpi-unit">(MOM)</span>
            </span>
          </div>
        )}
      </Tooltip>
    </div>
  );
};

import classNames from 'classnames';

const IconExternalLink = ({ size = 16, className, ...props }: any) => {
  return (
    <svg
      className={classNames('icon-external-link', className)}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -256 1850 1850"
      {...props}
    >
      <path
        d="M1438.373 818.95v320q0 119-84.5 203.5t-203.5 84.5h-832q-119 0-203.5-84.5t-84.5-203.5v-832q0-119 84.5-203.5t203.5-84.5h704q14 0 23 9t9 23v64q0 14-9 23t-23 9h-704q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113v-320q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19q-26 0-45-19l-176-176-652 652q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45q19-19 45-19h512q26 0 45 19t19 45z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconExternalLink;

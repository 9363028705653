import {
  SentimentFactResponse,
  SentimentMetricModel,
  SentimentMetricResponse,
  SentimentNPSChartResponse,
  SentimentNpsRequest,
} from '../types/metrics';
import { fetchAsync } from '../utils/fetch';
import { serializeQuery } from '../utils/stringUtils';

const toUrlSearchParams = (query: any) => {
  const urlParams = new URLSearchParams(serializeQuery(query));
  return urlParams.toString();
};

export const fetchSentiment = (): Promise<SentimentMetricResponse[]> => fetchAsync('/api/sentiment');
export const fetchSentimentFact = (dateId: number): Promise<SentimentFactResponse[]> =>
  fetchAsync(`/api/sentiment/fact?dateId=${dateId}`, {
    method: 'GET',
  });

export const updateSentimentMetric = (id: number, metric: SentimentMetricModel): Promise<SentimentMetricResponse> =>
  fetchAsync(`/api/sentiment/${id}`, {
    method: 'PUT',
    body: metric,
  });

export const deleteSentimentMetric = (id: number): Promise<void> =>
  fetchAsync(`/api/sentiment/${id}`, {
    method: 'DELETE',
  });

export const createSentimentMetric = (metric: SentimentMetricModel): Promise<SentimentMetricResponse> =>
  fetchAsync(`/api/sentiment`, {
    method: 'POST',
    body: metric,
  });

export const postSentimentTemplate = async (file: File, dateId) => {
  const data = new FormData();
  data.append('file', file);
  data.append('dateId', dateId);
  const result = fetchAsync('/api/sentiment/import', {
    method: 'POST',
    body: data,
    type: 'blob',
  });
  return result;
};

export const fetchSentimentTemplate = async () => {
  const result = await fetchAsync('/api/sentiment/template', {
    method: 'GET',
    type: 'blob',
    headers: {
      contentType: 'application/octet-stream',
    },
  });
  return result;
};

export const fetchSentimentNPSChart = (query: SentimentNpsRequest): Promise<SentimentNPSChartResponse> =>
  fetchAsync(`/api/sentiment/npschart?${toUrlSearchParams(query)}`);
